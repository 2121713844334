export enum ESubscriptionType {
  GOOGLE = "google",
  APPLE = "apple",
  ADMIN = "admin",
  V1 = "V1",
  PROMOCODE = "promo_code",
}

export const SubscriptionTypeLabel: { [key: string]: string } = {
  [ESubscriptionType.GOOGLE]: "google",
  [ESubscriptionType.APPLE]: "apple",
  [ESubscriptionType.ADMIN]: "admin",
  [ESubscriptionType.V1]: "v1",
  [ESubscriptionType.PROMOCODE]: "promo code",
};

export enum ESubsFields {
  Id = "_id",
  Email = "email",
  User = "user",
  SubscriptionType = "subscriptionType",
  TransactionId = "transactionId",
  TransactionDate = "transactionDate",
  ExpiresDate = "expiresDate",
  Promo = "promo",
  Amount = "amount",
  ProductId = "productId",
  Description = "description",
}

export const subsField: { [key: string]: { label: string; class: string } } = {
  [ESubsFields.Email]: { label: "Email", class: "md:w-[20%]" },
  [ESubsFields.SubscriptionType]: { label: "Source", class: "md:w-[7%]" },
  [ESubsFields.ProductId]: { label: "Subscription ID", class: "md:w-[15%]" },
  [ESubsFields.TransactionId]: { label: "Transaction ID", class: "md:w-[15%]" },
  [ESubsFields.Amount]: { label: "Amount", class: "md:w-[7%]" },
  [ESubsFields.Promo]: { label: "Promo", class: "md:w-[7%]" },
  [ESubsFields.TransactionDate]: { label: "Start Date", class: "md:w-[11%]" },
  [ESubsFields.ExpiresDate]: { label: "End Date", class: "md:w-[11%]" },
  [ESubsFields.Description]: { label: "Description", class: "md:w-[11%]" },
};

export enum EDateParam {
  START = "startDate",
  END = "endDate",
  TRANSACT_START = "transactionStartDate",
  TRANSACT_END = "transactionEndDate",
  EXPIRE_START = "expiresStartDate",
  EXPIRE_END = "expiresEndDate",
  MIN_START = "minStartDate",
  MAX_START = "maxStartDate",
  MIN_END = "minEndDate",
  MAX_END = "maxEndDate",
}

export const dateParamsQuery: Record<string, any> = Object.values(EDateParam).map((key) => ({ [key]: null }) )