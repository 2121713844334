import { Flex } from "@chakra-ui/react";
import {
  Card,
  DateRangePicker,
  HeaderTable,
  Pagination,
  SearchInput,
  TableAdmin,
} from "@components";
import { customStyles } from "@utils";
import { AdminContext } from "context";
import { EOrder } from "enums";
import {
  actionLoggingField,
  actionTypeList,
  EActionLoggingType,
  EEntityType,
  effectEntities,
} from "enums/action-logging";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { getActionLoggingList } from "services/action-logging";
import { IPagable, ISortTable } from "types";
import { IActionLogging } from "types/action-logging";
import { columnsActionLogging } from "./components";
import { PAGE_SIZE } from "@constants";

export const ActionLoggingList = () => {
  const navigate = useNavigate();
  const {
    filterDate,
    setIsFetching,
    isFetching,
    currentTimezone: { name, timezone },
  } = useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page") || "1";
  const searchParam = searchParams.get("search") || "";
  const [actionLogging, setActionLogging] =
    useState<IPagable<IActionLogging[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "createdAt",
    order: EOrder.DESC,
  });
  const { result: listTable }: { result: IActionLogging[] } = actionLogging || {
    result: [],
  };
  const [selectedActionType, setSelectedActionType] = useState<{
    label: string;
    value: EActionLoggingType;
  }>();

  const [selectedEntityType, setSelectedEntityType] = useState<{
    label: string;
    value: EEntityType;
  }>();

  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  useEffect(() => {
    (async () => {
      try {
        if (isNaN(parseInt(pageParam)))
          return navigate(`/admin/action-logging`);
        setIsFetching(true);
        const currentParams = Object.fromEntries(searchParams.entries());
        const res = await getActionLoggingList(
          filterDate,
          decodeURIComponent(searchParam),
          parseInt(pageParam),
          sortTable,
          selectedActionType?.value,
          selectedEntityType?.value,
          pageSize
        );
        const handledResult: IActionLogging[] = res.result.map((item) => {
          const data = JSON.parse(item.details);
          return { ...item, previousValue: data.before, newValue: data.after };
        });
        setActionLogging({ ...res, result: handledResult });
        if (res?.pageCount < parseInt(pageParam)) {
          setSearchParams({ ...currentParams, page: "1" });
        }
      } catch {}
      finally {
        setIsFetching(false);
      }
    })();
  }, [
    filterDate,
    searchParam,
    pageParam,
    sortTable,
    selectedActionType,
    selectedEntityType,
    pageSize,
  ]);

  const handleSortUsers = (sort: ISortTable) => {
    if (isFetching) return;
    setSortTable(sort);
  };

  const handleActionTypeChange = (newValue: {
    label: string;
    value: EActionLoggingType;
  }) => {
    setSelectedActionType(newValue);
  };

  const handleEntityTypeChange = (newValue: {
    label: string;
    value: EEntityType;
  }) => {
    setSelectedEntityType(newValue);
  };
  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Redemptions List"
        addonAfter={
          <Flex gap={4} wrap="wrap" justifyContent="start">
            <SearchInput />
            <DateRangePicker
              startParam="startDate"
              endParam="endDate"
              placeholder="Select Date Range"
            />
            <Select
              options={actionTypeList}
              placeholder="Select Action Type"
              isMulti={false}
              onChange={handleActionTypeChange}
              isClearable
              value={selectedActionType}
              styles={customStyles}
            />
            <Select
              options={effectEntities}
              placeholder="Select Effected Entity Type"
              isMulti={false}
              onChange={handleEntityTypeChange}
              isClearable
              value={selectedEntityType}
              styles={customStyles}
            />
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdmin
          listTable={listTable}
          sortTable={sortTable}
          setSortTable={handleSortUsers}
          columns={columnsActionLogging}
          columnsConfig={actionLoggingField}
          pageSize={pageSize}
        />
        <Pagination<IActionLogging>
          pageUsers={actionLogging}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
    </Card>
  );
};
