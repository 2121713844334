import { Button } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/table-core";
import { EDateTimeFormat, EPartnerStatus } from "enums";
import { ECodeFields, codeFieldLabel } from "enums/codes";
import moment from "moment";
import { MdOutlineModeEdit } from "react-icons/md";
import { ICodeRes } from "types";
import { headerClass, cellClass } from "views/share-class";

const columnHelper = createColumnHelper<ICodeRes>();
export const ColumnsCodes = [
  columnHelper.accessor(ECodeFields.Name, {
    id: ECodeFields.Name,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.Name]}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ECodeFields.MaxUses, {
    id: ECodeFields.MaxUses,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.MaxUses]}</p>
    ),
    cell: (info) => <div className={cellClass}>{info.getValue()}</div>,
  }),
  columnHelper.accessor(ECodeFields.Used, {
    id: ECodeFields.Used,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.Used]}</p>
    ),
    cell: (info) => <div className={cellClass}>{info.getValue()}</div>,
  }),
  columnHelper.accessor(ECodeFields.Duration, {
    id: ECodeFields.Duration,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.Duration]}</p>
    ),
    cell: (info) => <div className={cellClass}>{info.getValue()}</div>,
  }),
  columnHelper.accessor(ECodeFields.PartnerName, {
    id: ECodeFields.Partner,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.Partner]}</p>
    ),
    cell: (info) => <div className={cellClass}>{info.getValue()}</div>,
  }),
  columnHelper.accessor(ECodeFields.StartDate, {
    id: ECodeFields.StartDate,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.StartDate]}</p>
    ),
    cell: (info) => (
      <p className={`${cellClass}`}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(ECodeFields.EndDate, {
    id: ECodeFields.EndDate,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.EndDate]}</p>
    ),
    cell: (info) => (
      <p className={`${cellClass}`}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(ECodeFields.EndDate, {
    id: ECodeFields.Edit,
    header: () => (
      <p className={headerClass}>{codeFieldLabel[ECodeFields.Edit]}</p>
    ),
    cell: (info: CellContext<any, Date>) => (
      <Button
        colorScheme=""
        size="sm"
        padding={1}
        boxShadow="md"
        className={`!dark:text-white ${
          info?.row?.original?.status === EPartnerStatus.Inactive
            ? "!cursor-not-allowed"
            : ""
        }`}
      >
        <MdOutlineModeEdit
          size={18}
          color="black"
          className="dark:!text-white"
        />
      </Button>
    ),
  }),
];
