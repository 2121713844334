import { Flex, Spinner } from "@chakra-ui/react";

export const BackDropLoading = ({ isLoading }: { isLoading: boolean }) => {
  return isLoading ? (
    <Flex
      style={{ inset: 0, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      className="bg-slate-500 fixed z-[1001]"
      align="center"
      justify="center"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  ):  null;
};
