import { TagStatus } from "@components";
import { createColumnHelper } from "@tanstack/table-core";
import { formatPhoneNumber } from "@utils";
import { EUserFields, userFieldLabel, EDateTimeFormat } from "enums";
import moment from "moment";
import { IUserRes } from "types";
import { headerClass, cellClass } from "views/share-class";

const columnHelper = createColumnHelper<IUserRes>();

export const columnsUsers = [
  columnHelper.accessor(EUserFields.FirstName, {
    id: EUserFields.FirstName,
    header: () => (
      <p className={headerClass}>{userFieldLabel[EUserFields.FirstName]}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EUserFields.LastName, {
    id: EUserFields.LastName,
    header: () => (
      <p className={headerClass}>{userFieldLabel[EUserFields.LastName]}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EUserFields.Email, {
    id: EUserFields.Email,
    header: () => (
      <p className={headerClass}>{userFieldLabel[EUserFields.Email]}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EUserFields.PhoneNumber, {
    id: EUserFields.PhoneNumber,
    header: () => (
      <p className={headerClass}>{userFieldLabel[EUserFields.PhoneNumber]}</p>
    ),
    cell: (info) => (
      <p className={cellClass}>{formatPhoneNumber(info.getValue())}</p>
    ),
  }),
  columnHelper.accessor(EUserFields.CreatedTime, {
    id: EUserFields.CreatedTime,
    header: () => (
      <p className={headerClass}>{userFieldLabel[EUserFields.CreatedTime]}</p>
    ),
    cell: (info) => (
      <p className={cellClass}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(EUserFields.Status, {
    id: EUserFields.Status,
    header: () => (
      <p className={headerClass}>{userFieldLabel[EUserFields.Status]}</p>
    ),
    cell: (info) => (
      <div className={cellClass}>{<TagStatus status={info.getValue()} />}</div>
    ),
  }),
];
