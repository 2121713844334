export enum ERedemptions {
  Code = 'code',
  TransactionId = 'transactionId',
  TransactionDate = 'transactionDate',
  Duration = 'duration',
  Partner = 'partner',
  Email = 'email',
}

export const redemptionField: {[key: string]: { label: string; class: string } } = {
  [ERedemptions.Email]: { label: 'Email', class: 'md:w-[30%]' },
  [ERedemptions.Code]: { label: 'Code', class: 'md:w-[10%]' },
  [ERedemptions.Partner]: { label: 'Partner', class: 'md:w-[10%]' },
  [ERedemptions.Duration]: { label: 'Duration', class: 'md:w-[10%]' },
  [ERedemptions.TransactionDate]: { label: 'Date Redeemed', class: 'md:w-[20%]' },
  [ERedemptions.TransactionId]: { label: 'Transaction ID', class: 'md:w-[20%]' },
};