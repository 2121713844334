import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React, { useMemo, useRef } from "react";
import { EActionType } from "enums";

interface ConfirmDialogProps {
  showModal: boolean;
  onAccept: () => void;
  onDecline: () => void;
  title?: string;
  content?: React.ReactNode;
  isAcceptLoading?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  showModal,
  onAccept,
  onDecline,
  title = "delete",
  content = "Are you sure?",
  isAcceptLoading,
}) => {
  const cancelRef = useRef();

  const propsBtnAccept = useMemo(() => {
    switch (title) {
      case EActionType.DELETE:
        return {
          colorScheme: "red",
          variant: "solid",
        };
      case EActionType.LOGOUT:
        return {
          colorScheme: "purple",
          variant: "solid",
        };
      case EActionType.UPDATE:
        return {
          colorScheme: "blue",
          variant: "solid",
        };
      default:
        return {
          variant: "outline",
        };
    }
  }, [title]);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onDecline}
      isOpen={showModal}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent className="dark:bg-navy-800 dark:text-white">
        <AlertDialogHeader className="flex gap-1">
          <p className="capitalize">{title}</p>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{content}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onDecline}>
            Cancel
          </Button>
          <Button isLoading={isAcceptLoading} {...propsBtnAccept} ml={3} onClick={onAccept}>
            <p className="capitalize">{title}</p>
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
