export enum EPartnerFields {
  Id = "_id",
  Name = "name",
  CreatedTime = "createdTime",
  Status = "status",
  Action = "action",
  PartnerName = 'partnerName',
}

export const partnerFieldLabel: { [key: string]: string } = {
  [EPartnerFields.Name]: "Partner Name",
  [EPartnerFields.CreatedTime]: "Created Time",
  [EPartnerFields.Status]: "Status",
  [EPartnerFields.Action]: "Delete",
};

export enum EPartnerStatus {
  Inactive = "inactive",
  Active = "active",
}

export const EPartnerStatusdLabel: { [key: string]: string } = {
  [EPartnerStatus.Inactive]: "Inactive",
  [EPartnerStatus.Active]: "Active",
};

export const EPartnerStatusdLabelRevert: { [key: string]: string } = {
  [EPartnerStatus.Inactive]: "Active",
  [EPartnerStatus.Active]: "Inactive",
};

export enum EPartnerActionType {
  Delete = "delete",
  ChangeStatus = "changeStatus",
}

export enum EOtherFields {
  Partner = 'partner',
  MaxUser = 'maxUses',
  QTY = 'quantity',
  Duration = 'duration',
  StartDate = 'startTime',
  EndDate = 'endTime'
}
export const partnerSubsField: { [key: string]: { label: string; class: string } } = {
  [EPartnerFields.Name]: { label: 'Name', class: 'md:w-[20%]' },
  [EPartnerFields.PartnerName]: { label: 'Partner name', class: 'md:w-[20%]' },
  [EPartnerFields.CreatedTime]: { label: 'Create time', class: 'md:w-[7%]' },
  [EPartnerFields.Status]: { label: 'Status', class: 'md:w-[15%]' },
  [EOtherFields.Partner]: { label: 'Select partner', class: 'md:w-[20%]' },
  [EOtherFields.MaxUser]: { label: 'Max used', class: 'md:w-[20%]' },
  [EOtherFields.QTY]: { label: 'Quantity', class: 'md:w-[20%]' },
  [EOtherFields.Duration]: { label: 'Duration', class: 'md:w-[20%]' },
  [EOtherFields.StartDate]: { label: 'Start Date', class: 'md:w-[20%]' },
  [EOtherFields.EndDate]: { label: 'End Date', class: 'md:w-[20%]' },
};
