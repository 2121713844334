import React, { useContext, useState } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { FaRegUserCircle } from "react-icons/fa";
import { getPageName, removeToken } from "@utils";
import { FaArrowLeft } from "react-icons/fa";
import { AdminContext } from "context";
import { IParams } from "types";
import { signOutAdmin } from "services";
import { ConfirmDialog } from "@components";
import { EActionType } from "enums";

export const Navbar = (props: {
  onOpenSidenav: () => void;
  secondary?: boolean | string;
}) => {
  const colorIcon = "text-navy-700 dark:text-white";
  const { onOpenSidenav } = props;
  const [darkmode, setDarkmode] = useState(false);
  const [comfirmSignOut, setConfirmSignOut] = useState(false);
  const { email } = useContext(AdminContext);
  const navigate = useNavigate();
  const location = useLocation();
  const secondPath = location.pathname.split('/')[2];
  const { userId } = useParams<IParams>();
  const [searchParams] = useSearchParams();

  const handleSignOut = async () => {
    try {
      setConfirmSignOut(false);
      await signOutAdmin();
    } catch (error) {
      console.log("log-error --- ", error);
    } finally {
      removeToken("AccessToken");
      navigate("/auth");
    }
  }

  return (
    <nav className="sticky top-4 z-40 mb-4 flex h-[73px] flex-row items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="w-auto md:w-3/5">
        {userId ? (
          <div className="flex h-full items-center gap-2 rounded bg-white p-1 font-bold shadow-xl shadow-shadow-500 dark:text-white dark:!bg-navy-800 dark:shadow-none">
            <button
              className="h-full rounded-full p-4 text-navy-700 hover:bg-gray-100 dark:text-white dark:hover:bg-navy-700"
              onClick={() => navigate(`/admin/${secondPath}?${searchParams.toString()}`)}
            >
              <FaArrowLeft />
            </button>
            <span className="hidden truncate pr-4 md:block">{email}</span>
          </div>
        ) : (
          <Link to="#" className="shrink text-3xl font-bold capitalize hover:text-navy-700 dark:text-white">
            {getPageName(location.pathname.split("/")[2])}
          </Link>
        )}
      </div>

      <div className="relative flex h-full w-[200px] items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:gap-1 xl:gap-2">
        <span
          className={`flex cursor-pointer text-xl ${colorIcon} xl:hidden`}
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 ${colorIcon}" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={<div></div>}
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        /> */}
        <div
          className={`cursor-pointer ${colorIcon}`}
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className={`h-4 w-4 ${colorIcon}`} />
          ) : (
            <RiMoonFill className={`h-4 w-4 ${colorIcon}`} />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <FaRegUserCircle
              className={`h-6 w-6 rounded-full ${colorIcon} cursor-pointer`}
            />
          }
          children={
            <div className="flex w-48 flex-col justify-start rounded-[8px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-3">
                <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white gap-2">
                  <FaRegUserCircle
                    className={`h-5 w-5 rounded-full ${colorIcon}`}
                  />
                  Hey, Admin
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20" />

              <div className="p-3 flex flex-col hover:bg-gray-100 dark:hover:bg-navy-800">
                {/* <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white py-2"
                >
                  Profile Settings
                </a> */}
                <p
                  className="text-sm font-bold text-red-500 cursor-pointer"
                  onClick={() => setConfirmSignOut(true)}
                >
                  Log out
                </p>
              </div>
            </div>
          }
          classNames={"py-3 top-8 -left-[180px] w-max"}
        />
      </div>
      <ConfirmDialog
        showModal={comfirmSignOut}
        onAccept={handleSignOut}
        onDecline={() => setConfirmSignOut(false)}
        title={EActionType.LOGOUT}
        content={"Are you sure you want to log out?"}
      />
    </nav>
  );
};
