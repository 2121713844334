import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Flex } from "@chakra-ui/react";
import { PartnerFormSub } from "components/partnerForm";
import { AdminContext } from "context";
import { EDateTimeFormat, EToastStatus, EPartnerFields } from "enums";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { updatePartner } from "services";
import { IPartnerRes } from "types";

interface ModalEditPartnerProps {
  showModal: boolean;
  onDecline: () => void;
  data?: IPartnerRes;
  action: () => void;
}

export const ModalEditPartner = ({
  showModal,
  onDecline,
  data,
  action,
}: ModalEditPartnerProps) => {
  const cancelRef = useRef(null);
  const [fields, setFields] = useState<IPartnerRes>(data);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<IPartnerRes>();
  const { showToast } = useContext(AdminContext);

  useEffect(() => {
    setFields({
      ...data,
    });
  }, [data]);

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setError(null);
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleUpdatePartner = async () => {
    try {
      if (!fields?.name) {
        return setError({ ...error, name: "Name is require!" });
      }
      setIsSubmitting(true);
      await updatePartner(fields);
      showToast("Success", "Update Partner success!", EToastStatus.SUCCESS);
      action();
      onDecline();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Modal
      finalFocusRef={cancelRef}
      onClose={onDecline}
      isOpen={showModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Partner</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex gap={6} flexDirection="column">
            <Flex gap={6}>
              <PartnerFormSub.Input
                name={EPartnerFields.Name}
                value={fields?.name}
                onChange={handleChangeInput}
                error={error?.name}
              />
            </Flex>
            <Flex gap={6}>
              <PartnerFormSub.Input
                disabled={true}
                name={EPartnerFields.CreatedTime}
                value={moment(fields?.createdTime).format(
                  EDateTimeFormat.MONTH_DAY_YEAR
                )}
              />
            </Flex>
            <Flex gap={6}>
              <PartnerFormSub.Input
                disabled={true}
                readOnly
                name={EPartnerFields.Status}
                value={fields?.status}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter gap={5}>
          <Button
            isLoading={isSubmitting}
            isDisabled={!!error}
            colorScheme="purple"
            onClick={handleUpdatePartner}
          >
            Save
          </Button>
          <Button ref={cancelRef} onClick={onDecline}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
