import moment from "moment";
import { decodeToken } from "react-jwt";
import { IJwtPayload } from "types";
import { getToken } from "./storage";

const calculateTokenRefreshDelay = (
  bufferTimeInMinutes = 5
): number => {
  try {
    const accessToken = getToken("AccessToken");
    const { expireTime } = decodeToken(accessToken) as IJwtPayload;

    if (!moment(expireTime).isValid()) {
      return 0;
    }

    const now = moment();
    const delayInMilliseconds =
      moment(expireTime).diff(now, "milliseconds") -
      bufferTimeInMinutes * 60 * 1000;

    return delayInMilliseconds;
  } catch {
    return 0;
  }
};

export { calculateTokenRefreshDelay };
