import { EmailRegex } from "enums";

export const formatPhoneNumber = (phone?: string) => {
  if (!phone) return '';
  const phoneNumber = phone?.replace(/\D/g, '').substring(0, 10);
  const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
  return formattedPhoneNumber;
};

export const parsePhoneNumber = (formattedPhone?: string) => {
  if (!formattedPhone) return '';
  const numericCharacters = formattedPhone.replace(/\D/g, '');
  return numericCharacters;
};

export const validateEmail = (email?: string) => {
  if (!email) return false;
  return EmailRegex.test(String(email).toLowerCase());
};