import { Text, TextProps } from "@chakra-ui/react";

interface ITextTimeZone extends Omit<TextProps, "children"> {
  value: string;
}

export const TextTimezone = ({value, ...props}: ITextTimeZone) => {
  return (
    <Text opacity="0.6" fontSize="sm" {...props}>
      ({value})
    </Text>
  );
};
