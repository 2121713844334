import { Image } from "@chakra-ui/react";
import { useState } from "react";

interface IAvatarDetail {
  firstName?: string;
  lastName?: string;
  avatar?: string;
  setNewAvatar?: (file: File) => void;
}

export const UserAvatar = ({
  firstName = "",
  lastName = "",
  avatar,
  setNewAvatar,
}: IAvatarDetail) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setNewAvatar(selectedFile);
    }
  };

  return (
    <label
      htmlFor="avatarUpload"
      className="relative h-[150px] w-[150px] cursor-pointer overflow-hidden rounded-full shadow-xl md:h-[200px] md:w-[200px] hover:opacity-50 ease-in-out"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        src={avatar}
        alt={`${firstName} ${lastName}`}
        height="100%"
        width="100%"
        objectFit="cover"
        borderRadius="full"
        fallback={
          <div className="h-full w-full rounded-full bg-gray-200 dark:bg-gray-800">
            <div className="flex h-full w-full items-center justify-center text-6xl font-extrabold capitalize text-navy-700 dark:text-gray-500">
              {`${firstName && firstName[0]} ${lastName && lastName[0]}`}
            </div>
          </div>
        }
      />
      {isHovered && (
        <div className="bg-black absolute inset-0 flex items-center justify-center rounded-full">
          <label htmlFor="avatarUpload">
            <p className="text-black text-2xl font-bold flex items-center justify-center gap-2">Upload</p>
          </label>
        </div>
      )}
      <input
        type="file"
        id="avatarUpload"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </label>
  );
};
