import {
  InputProps,
  Select,
  SelectProps,
  Skeleton,
  Textarea,
  TextareaProps,
  Text
} from "@chakra-ui/react";
import { InputField, TextTimezone } from "@components";
import { AdminContext } from "context";
import { ReactNode, useContext } from "react";

interface IDetailFieldProps extends InputProps {
  isInvalid?: boolean;
  errors?: string;
  readOnly?: boolean;
  showTimeZone?: boolean;
}

interface IDetailTextareaProps extends TextareaProps {
  isInvalid?: boolean;
  errors?: string;
  readOnly?: boolean;
}

interface IDetailSelectProps extends SelectProps {
  isInvalid?: boolean;
  errors?: string;
  readOnly?: boolean;
}

interface ILabelDetail {
  errors?: string;
  label?: ReactNode;
  children?: ReactNode;
  showTimeZone?: boolean;
}

const disabledStyle = { opacity: 0.7, color: 'inherit' };

export const FormDetail = {
  Input: (props: IDetailFieldProps) => {
    const { placeholder, errors, showTimeZone, ...rest } = props;
    return (
      <LabelDetail label={placeholder} showTimeZone={showTimeZone} errors={errors}>
        <InputField
          variant="flushed"
          className="h-[40px] dark:bg-navy-800"
          _disabled={disabledStyle}
          {...rest}
        />
      </LabelDetail>
    );
  },
  TextArea: (props: IDetailTextareaProps) => {
    const { placeholder, errors, ...rest } = props;
    return (
    <LabelDetail label={placeholder} errors={errors}>
      <Textarea
        variant="flushed"
        className="dark:bg-navy-800"
        _disabled={disabledStyle}
        {...rest} 
      />
    </LabelDetail>
  )},
  Select: (props: IDetailSelectProps) => {
    const { placeholder, errors, ...rest } = props;
    return (
    <LabelDetail label={placeholder} errors={errors}>
      <Select
        variant="flushed"
        className="dark:bg-navy-800"
        _disabled={disabledStyle}
        {...rest} 
      />
    </LabelDetail>
  )},
};

const LabelDetail = ({
  errors,
  label,
  children,
  showTimeZone
}: ILabelDetail ) => {
  const { isFetching, currentTimezone: { timezone } } = useContext(AdminContext);
  return (
    <label className="flex flex-1 flex-col gap-2 p-2">
      <div className="text-md break-words font-bold text-navy-700 dark:text-white md:text-lg flex align-center gap-2">
        {label} {showTimeZone && <TextTimezone value={timezone}/>}
      </div>
      {isFetching ? (
        <div className="h-[40px] border-b-[1px] border-gray-200 py-2">
          <Skeleton borderRadius="5px" className="h-[20px]" />
        </div>
      ) : (children)}
      {errors && <div className="text-md text-red-500">{errors}</div>}
    </label>
  );
};
