import { EDateParam } from "enums";
import { IFilterDate } from "types";

export const getFilterDateParams = (filter: IFilterDate, startParam: string, endParam?: string) => {
  const filterDate = {
    ...(filter?.[startParam as EDateParam]
      && { [startParam]: filter?.[startParam as EDateParam] }),
    ...(filter?.[endParam as EDateParam]
      && { [endParam]: filter?.[endParam as EDateParam] }),
  };
  return filterDate;
};
