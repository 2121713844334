enum EEligibleEmployeesColumns {
  Id = "_id",
  EmployeeInfo = "employeeInfo",
  EmployerName = "employerName",
  EmployerNickname = "employerNickname",
  TPAName = "TPAName",
  PartnerName = "partnerName",
  Code = "code",
  CreatedTime = "generatedDate",
  CodeFirstSeenDate = "codeFirstSeenDate",
  RedeemedDate = "redeemedDate",
  TermedDate = "termedDate",
}

const eligibleEmployeesColumns: {
  [key: string]: { label: string; class: string };
} = {
  [EEligibleEmployeesColumns.EmployerName]: {
    label: "Employer Name",
    class: "min-w-[200px] md:w-[12%]",
  },
  [EEligibleEmployeesColumns.EmployerNickname]: {
    label: "Employer Nickname",
    class: "min-w-[150px] md:w-[12%]",
  },
  [EEligibleEmployeesColumns.TPAName]: {
    label: "TPA Name",
    class: "min-w-[150px] md:w-[12%]",
  },
  [EEligibleEmployeesColumns.PartnerName]: {
    label: "Partner Name",
    class: "min-w-[150px] md:w-[12%]",
  },
  [EEligibleEmployeesColumns.EmployeeInfo]: {
    label: "Employee ID",
    class: "min-w-[200px] md:w-[12%]",
  },
  [EEligibleEmployeesColumns.Code]: {
    label: "Premium Code",
    class: "min-w-[120px] md:w-[8%]",
  },
  [EEligibleEmployeesColumns.CreatedTime]: {
    label: "Date Created",
    class: "min-w-[140px] md:w-[8%]",
  },
  [EEligibleEmployeesColumns.CodeFirstSeenDate]: {
    label: "Date First Seen",
    class: "min-w-[140px] md:w-[8%]",
  },
  [EEligibleEmployeesColumns.TermedDate]: {
    label: "Date Termed",
    class: "min-w-[140px] md:w-[8%]",
  },
  [EEligibleEmployeesColumns.RedeemedDate]: {
    label: "Date Redeemed",
    class: "min-w-[140px] md:w-[8%]",
  },
};

export { EEligibleEmployeesColumns, eligibleEmployeesColumns };
