import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Flex, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { SystemUserFormSub } from "components/systemUserForm";
import { AdminContext } from "context";
import { ERoleName, ESystemUser, ESubRole, EToastStatus } from "enums";
import { useFormik } from "formik";
import { ChangeEvent, useContext, useMemo, useRef, useState } from "react";
import { addNewSystemUser } from "services";
import { ISystemUser } from "types";
import * as Yup from "yup";

interface AddSystemUserModalProps {
  showModal: boolean;
  onDecline: () => void;
  action: () => void;
  data?: ISystemUser;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  email: Yup.string().required(),
  subRole: Yup.mixed<ESubRole>()
    .oneOf(Object.values(ESubRole), "Invalid system user role")
    .required("System user role is required"),
});

export const AddSystemUserModal = ({
  showModal,
  onDecline,
  data,
  action,
}: AddSystemUserModalProps) => {
  const cancelRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<ISystemUser>();
  const { showToast } = useContext(AdminContext);

  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subRole: ESubRole.EMPLOYER,
  };

  const handleAddNewSystemUser = async () => {
    try {
      setIsSubmitting(true);
      await addNewSystemUser({ ...values, role: ERoleName.SYSTEM_USER });
      showToast("Success", "Create System User success!", EToastStatus.SUCCESS);
      action();
      onDecline();
      resetForm();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema,
    onSubmit: handleAddNewSystemUser,
  });
  const { values, setFieldValue, resetForm, isValid, dirty } = formik;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const type = e?.target?.name;
    if (!type) return;
    setFieldValue(type, value);
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    formik.setFieldValue(ESystemUser.SubRole, selectedValue);
  };

  const handleClose = () => {
    onDecline();
    resetForm();
  };

  const isDisabled = useMemo(() => {
    return !dirty || !isValid;
  }, [dirty, isValid]);

  return (
    <Modal
      finalFocusRef={cancelRef}
      onClose={handleClose}
      isOpen={showModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add System User</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex gap={6} flexDirection="column">
            <Flex gap={6}>
              <SystemUserFormSub.Input
                name={ESystemUser.FirstName}
                value={values?.firstName}
                onChange={handleInputChange}
                error={error?.firstName}
              />
            </Flex>
            <SystemUserFormSub.Input
              name={ESystemUser.LastName}
              value={values?.lastName}
              onChange={handleInputChange}
              error={error?.lastName}
            />
            <Flex gap={6}>
              <SystemUserFormSub.Input
                name={ESystemUser.Email}
                value={values?.email}
                onChange={handleInputChange}
                error={error?.email}
              />
            </Flex>
            <Flex gap={6}>
              <SystemUserFormSub.Input
                name={ESystemUser.PhoneNumber}
                value={values?.phoneNumber}
                onChange={handleInputChange}
                error={error?.phoneNumber}
              />
            </Flex>
            <Flex gap={6}>
              <FormControl>
                <FormLabel display="flex" alignItems="center" gap={2}>
                  Role
                </FormLabel>
                <Select onChange={handleRoleChange} defaultValue="">
                  <option value="" disabled hidden>
                    Select
                  </option>
                  <option value={ESubRole.EMPLOYER}>Employer</option>
                  <option value={ESubRole.ADMIN}>Admin</option>
                </Select>
              </FormControl>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter gap={5}>
          <Button
            isLoading={isSubmitting}
            isDisabled={isDisabled}
            colorScheme="purple"
            onClick={handleAddNewSystemUser}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
