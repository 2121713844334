import { Tag } from "@chakra-ui/react";

export const TagStatus = ({ status }: { status: string }) => (
  <Tag
    size="md"
    colorScheme={status === "active" ? "green" : "red"}
    className="w-[75px] text-center capitalize flex justify-center items-center"
    style={{ fontWeight: "bold" }}
  >
    {status}
  </Tag>
);
