import {
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/react";
import { InputField } from "@components";
import { EColor, EDateParam, EDateTimeFormat, dateParamsQuery } from "enums";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../style.css"
import { FaCalendar } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { useSearchParams } from "react-router-dom";
import { AdminContext } from "context";

const initialDateRange: Range = {
  startDate: null,
  endDate: null,
  key: "selection",
};

interface IDatePickerProps {
  placeholder?: string,
  startParam: string,
  endParam: string,
}

export const DateRangePicker = ({placeholder, startParam, endParam}: IDatePickerProps ) => {
  const { isFetching, setFilterDate, filterDate } = useContext(AdminContext);
  const [dateRange, setDateRange] = useState<Range[]>([initialDateRange]);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get(startParam) || "";
  const endDateParam = searchParams.get(endParam) || "";

  useEffect(() => {
    const startDate = moment(startDateParam);
    const endDate = moment(endDateParam);
    if (startDate.isValid() || endDate.isValid()){
      setDateRange([
        {
          ...(startDate.isValid() && { startDate: startDate.toDate() }),
          ...(endDate.isValid() && { endDate: endDate.toDate() }),
          key: "selection",
        },
      ]);
    }
  }, [startDateParam, endDateParam]);

  useEffect(() => {
    if (isFetching) return;
    const dateParamsArray = Object.values(EDateParam);
    dateParamsArray.forEach((param) => {
      const value = moment(searchParams.get(param));
      if (value.isValid()) {
        dateParamsQuery[param] = value.local().format(EDateTimeFormat.LOCAL_WITH_OFFSET)
      } else {
        dateParamsQuery[param] = null;
      }
    });
    setFilterDate({...filterDate,...dateParamsQuery});
  }, [startDateParam, endDateParam]);

  const currentParams = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

  const valueDateRange = useMemo(() => {
    const startDate = moment(startDateParam);
    const endDate = moment(endDateParam);
    const dateFormat = EDateTimeFormat.MONTH_DAY_YEAR;
    switch (true) {
      case startDate.isValid() && endDate.isValid():
        return `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`;
      case startDate.isValid():
        return `${startDate.format(dateFormat)} - Future`;
      case endDate.isValid():
        return `Past - ${endDate.format(dateFormat)}`;
      default:
        return "";
    }
  }, [startDateParam, endDateParam]);

  const handleDateRange = (item: RangeKeyDict) => {
    if (isFetching) return;
    const startDate = moment(item["selection"].startDate);
    const endDate = moment(item["selection"].endDate);
    setSearchParams({
      ...currentParams,
      ...(startDate.isValid() && { [startParam]: startDate.format(EDateTimeFormat.DATE) }),
      ...(endDate.isValid() && { [endParam]: endDate.format(EDateTimeFormat.DATE) }),
     });
  };

  const handleRemoveDateRange = (e: any) => {
    if (isFetching) return;
    e.stopPropagation();
    setSearchParams({ ...currentParams, [startParam]: "", [endParam]: "" });
    setDateRange([initialDateRange]);
  };

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <div className="w-full px-0 font-normal sm:w-[250px]">
          <InputGroup>
            <InputField
              value={valueDateRange}
              placeholder={placeholder}
            />
            <InputRightElement>
            {valueDateRange ? (
              <TiDelete
                className={`${isFetching && "text-gray-500"} text-lg`}
                onClick={handleRemoveDateRange}
              />
              ) : (
                <FaCalendar />
              )}
            </InputRightElement>
          </InputGroup>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <DateRange
          className="date-range-picker-custom"
          dateDisplayFormat={EDateTimeFormat.MONTH_DAY_YEAR_DATEPICKER}
          rangeColors={[EColor.Purple50]}
          editableDateInputs={true}
          onChange={handleDateRange}
          retainEndDateOnFirstSelection
          ranges={dateRange}
          {...isFetching && startDateParam && endDateParam && { minDate: new Date(), maxDate: new Date() }}
          dragSelectionEnabled
        />
      </PopoverContent>
    </Popover>
  );
};
