import { apiInstance } from "@apis";
import { PAGE_SIZE } from "@constants";
import { getFilterDateParams } from "@utils";
import { EApiUrl } from "enums";
import { EActionLoggingType, EEntityType } from "enums/action-logging";
import { IBaseRes, IFilterDate, IPagable, ISortTable } from "types";
import { IActionLogging } from "types/action-logging";

export const getActionLoggingList = async (
  filterDate?: IFilterDate,
  search?: string,
  page?: number,
  sort?: ISortTable,
  action?: EActionLoggingType,
  entityType?: EEntityType,
  size = PAGE_SIZE
) => {
  const filter = getFilterDateParams(filterDate, "startDate", "endDate");
  const { data } = await apiInstance.get<IBaseRes<IPagable<IActionLogging[]>>>(
    EApiUrl.ACTION_LOGGING_URL,
    {
      params: {
        search,
        ...sort,
        ...filter,
        page,
        size,
        action,
        entityType,
      },
    }
  );
  return data?.data;
};
