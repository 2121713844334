import { ReactNode } from 'react';
import { CiBarcode } from "react-icons/ci";
import { FaRegHandshake, FaUserGroup } from 'react-icons/fa6';
import { MdRedeem } from "react-icons/md";
import { PiSubtitlesFill } from 'react-icons/pi';
import { Route } from 'react-router-dom';
import { GrUserManager } from "react-icons/gr";
import { GrUserWorker } from "react-icons/gr";
import { CodeList, PartnerList, RedemptionList, SubscriptionDetail, SubscriptionList, UserDetail, UserList, EligibleEmployees, EmployerList } from 'views';
import { ActionLoggingList } from "views/action-logging";
import { MdOutlinePendingActions } from "react-icons/md";
import { SystemUserList } from "views/system-users/SystemUserList";

interface IRoute {
  name: string;
  layout: string;
  icon: ReactNode;
  path: string;
  component?: ReactNode;
  children?: { path: string; component: ReactNode }[];
}

export const routes: IRoute[] = [
  {
    name: "Users",
    layout: "/admin",
    icon: <FaUserGroup className="h-6 w-6" />,
    path: "users",
    children: [
      {
        path: "",
        component: <UserList />,
      },
      {
        path: ":userId",
        component: <UserDetail />,
      },
    ],
  },
  {
    name: "System Users",
    layout: "/admin",
    icon: <FaUserGroup className="h-6 w-6" />,
    path: "system-users",
    children: [
      {
        path: "",
        component: <SystemUserList />,
      },
      {
        path: ":userId",
        component: <UserDetail />,
      },
    ],
  },
  {
    name: "Subscription",
    layout: "/admin",
    icon: <PiSubtitlesFill className="h-6 w-6" />,
    path: "subscription",
    children: [
      {
        path: "",
        component: <SubscriptionList />,
      },
      {
        path: ":userId",
        component: <SubscriptionDetail />,
      },
    ],
  },
  {
    name: "Partners",
    layout: "/admin",
    icon: <FaRegHandshake className="h-6 w-6" />,
    path: "partners",
    children: [
      {
        path: "",
        component: <PartnerList />,
      },
      {
        path: ":partnerId",
        component: <PartnerList />,
      },
    ],
  },
  {
    name: "Employers",
    layout: "/admin",
    icon: <GrUserManager className="h-6 w-6" />,
    path: "employers",
    component: <EmployerList />,
  },
  {
    name: "Eligible Employees",
    layout: "/admin",
    icon: <GrUserWorker className="h-6 w-6" />,
    path: "eligible-employees",
    component: <EligibleEmployees />,
  },
  {
    name: "Codes",
    layout: "/admin",
    icon: <CiBarcode className="h-6 w-6" />,
    path: "codes",
    component: <CodeList />,
  },
  {
    name: "Redemptions",
    layout: "/admin",
    icon: <MdRedeem className="h-6 w-6" />,
    path: "redemptions",
    component: <RedemptionList />,
  },
  {
    name: "Action Logging",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "action-logging",
    component: <ActionLoggingList />,
  },
];

export const renderRoutes = () => {
  return routes.map((route, index: number) => (
    <Route key={index} path={route.path} element={route.component}>
      {route.children && route.children.map((childRoute, idx) => (
        <Route key={`${index}-${idx}`} path={childRoute.path} element={childRoute.component} />
      ))}
    </Route>
  ));
};