import { apiInstance } from "@apis";
import { PAGE_SIZE } from "@constants";
import { EApiUrl } from "enums";
import {
  IBaseRes,
  IFilterDate,
  INewSystemUSer,
  IPagable,
  ISortTable,
  ISystemUser,
} from "types";

export const getSystemUserList = async (
  filterDate: IFilterDate,
  search: string,
  page: number,
  sort: ISortTable,
  pageSize?: number
) => {
  const filterPayload = {
    ...(filterDate?.startDate && { startDate: filterDate.startDate }),
    ...(filterDate?.endDate && { endDate: filterDate.endDate }),
  };
  const { data } = await apiInstance.get<IBaseRes<IPagable<ISystemUser[]>>>(
    EApiUrl.SYSTEM_USERS,
    {
      params: {
        search,
        ...filterPayload,
        ...sort,
        page,
        size: pageSize ?? PAGE_SIZE,
      },
    }
  );
  return data?.data;
};

export const addNewSystemUser = async (user: INewSystemUSer) => {
  const data = await apiInstance.post(EApiUrl.USER_URL, user);
  return data;
};
