import { Flex } from "@chakra-ui/react";
import { AdminContext } from "context";
import { ReactNode, useContext } from "react";

export const HeaderTable = ({ title, addonAfter } : { title: string, addonAfter?: ReactNode }) => {
  const { currentTimezone: { name, timezone } } = useContext(AdminContext);

  return (
    <header className="relative flex flex-col items-stretch justify-between gap-2 sm:flex-nowrap md:gap-3">
      <Flex align="center" justifyContent="space-between" flex={1}>
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {title}
        </div>
        <div className="font-semi flex text-sm text-navy-700 dark:text-white">
          *Timezone: {`${name} ${timezone}`}
        </div>
      </Flex>
      {addonAfter}
    </header>
  );
};
