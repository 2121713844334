import { getToken } from "@utils";
import { AUTH_TYPE, LOGIN_SCREEN } from "enums";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyWithEmailOrSMS } from "services";
import { IFormSignInAdmin } from "types";
import { RequestOTP } from "./RequestOTP";
import { VerifyOTP } from "./VerifyOTP";

export const SignIn = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [loginForm, setLoginForm] = useState<IFormSignInAdmin>({
    username: "",
    password: "",
  });
  const [loginScreen, setLoginScreen] = useState<LOGIN_SCREEN>(
    LOGIN_SCREEN.REQUEST_OTP
  );
  const [authType, setAuthType] = useState<AUTH_TYPE>(AUTH_TYPE.EMAIL);

  useEffect(() => {
    if (loginForm.username && loginForm.password) setIsValid(true);
  }, [loginForm]);

  useEffect(() => {
    const token = getToken("AccessToken");
    if (token) {
      navigate("/admin");
    }
  }, []);

  const handleSignIn = useCallback(
    async (type?: AUTH_TYPE) => {
      if (!loginForm.username || !loginForm.password) return setIsValid(false);

      try {
        setIsSubmitting(true);

        await verifyWithEmailOrSMS(type ?? authType, loginForm);

        setLoginScreen(LOGIN_SCREEN.VERIFY_OTP);

        if (type) {
          setAuthType(type);
        }
      } catch {
        setIsValid(false);
      } finally {
        setIsSubmitting(false);
      }
    },
    [loginForm, authType]
  );

  if (loginScreen === LOGIN_SCREEN.REQUEST_OTP) {
    return (
      <RequestOTP
        onSignIn={handleSignIn}
        isSubmitting={isSubmitting}
        setLoginForm={setLoginForm}
        loginForm={loginForm}
        setIsValid={setIsValid}
        isValid={isValid}
      />
    );
  }

  return <VerifyOTP resendOTP={handleSignIn} email={loginForm.username} />;
};
