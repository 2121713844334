import { useState } from "react";
import { FaCheck, FaRegCopy } from "react-icons/fa6";

export const CopyButton = ({ value }: { value: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div>
      {copied ? (
        <FaCheck className="transition-all" />
      ) : (
        <FaRegCopy
          className="transition-all hover:opacity-70"
          onClick={handleCopy}
        />
      )}
    </div>
  );
};
