export enum ECodeFields {
    Id = '_id',
    Name = 'name',
    MaxUses = 'maxUses',
    Used = 'attempts',
    Duration = 'duration',
    Partner = 'partner',
    StartDate = 'startTime',
    EndDate = 'endTime',
    Edit = 'edit_code',
    CodeName = 'code_name',
    PartnerName = 'partnerName',
    Quantity = 'quantity',
}

export const codeFieldLabel: { [key: string]: string } = {
    [ECodeFields.Name]: 'Name',
    [ECodeFields.MaxUses]: 'Max Uses',
    [ECodeFields.Used]: 'Used',
    [ECodeFields.Duration]: 'Duration',
    [ECodeFields.Partner]: 'Partner',
    [ECodeFields.StartDate]: 'Start Date',
    [ECodeFields.EndDate]: 'End Date',
    [ECodeFields.Edit]: 'Edit',
    [ECodeFields.CodeName]: 'Code Name',
    [ECodeFields.PartnerName]: 'Partner Name',
    [ECodeFields.Quantity]: 'Quantity',
};
