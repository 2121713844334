import { apiInstance } from "@apis";
import { EApiUrl } from "enums";
import {
  IBaseRes,
  ICodeRes,
  IEligibleEmployeeForm,
  IGetListEmployeeParams,
  IReadFileImportEmployee,
} from "types";

const getEligibleEmployees = async (params: IGetListEmployeeParams) => {
  const { data } = await apiInstance.get(`${EApiUrl.ELIGIBLE_EMPLOYEE_URL}`, {
    params,
  });
  return data;
};

const regenerateCodeForEmployee = async (
  employeeId: string
): Promise<ICodeRes> => {
  const { data } = await apiInstance.put(
    `${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/${employeeId}/regenerate-code`
  );
  return data?.data;
};

const importEligibleEmployees = async (employerId: string, data: FormData) => {
  const res = await apiInstance.post(
    `${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/import`,
    data,
    {
      params: { employerId },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

const readFileImportEligibleEmployees = async (
  employerId: string,
  data: FormData
) => {
  const res = await apiInstance.post<IBaseRes<IReadFileImportEmployee>>(
    `${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/read-file-import`,
    data,
    {
      params: { employerId },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res?.data.data;
};

const exportEligibleEmployees = async (employerId: string) => {
  const { data } = await apiInstance.get(
    `${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/export`,
    {
      params: { employerId },
    }
  );
  return data?.data;
};

const downloadEligibleEmployeesTemplate = async () => {
  const { data } = await apiInstance.get(
    `${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/template`,
    {
      responseType: "blob",
    }
  );
  return data;
};

const getEligibleEmployee = async (id: string) => {
  const { data } = await apiInstance.get(
    `${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/${id}`
  );
  return data?.data;
};

const deleteEligibleEmployee = async (id: string) => {
  await apiInstance.delete(`${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/${id}`);
};

const createEligibleEmployee = async (data: IEligibleEmployeeForm) => {
  await apiInstance.post(`${EApiUrl.ELIGIBLE_EMPLOYEE_URL}`, data);
};

const updateEligibleEmployee = async (
  id: string,
  data: IEligibleEmployeeForm
) => {
  await apiInstance.put(`${EApiUrl.ELIGIBLE_EMPLOYEE_URL}/${id}`, data);
};

export {
  getEligibleEmployees,
  regenerateCodeForEmployee,
  exportEligibleEmployees,
  importEligibleEmployees,
  readFileImportEligibleEmployees,
  downloadEligibleEmployeesTemplate,
  deleteEligibleEmployee,
  updateEligibleEmployee,
  createEligibleEmployee,
  getEligibleEmployee,
};
