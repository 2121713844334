import { Button, Flex } from "@chakra-ui/react";
import { Card, ConfirmDialog, FormDetail } from "@components";
import { AxiosError } from "axios";
import { AdminContext } from "context";
import {
  EActionType,
  EDateTimeFormat,
  ESubsFields,
  ESubscriptionType,
  EToastStatus,
  SubscriptionTypeLabel,
  subsField,
} from "enums";
import { useFormik } from "formik";
import moment from "moment";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSubscription,
  getCurrentSubscription,
  updateSubscriptionInfo,
} from "services";
import { ISubscriptionRes } from "types";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  subscriptionType: Yup.string().required("Subscription type is required"),
  transactionId: Yup.string().required("Transaction ID is required"),
  expiresDate: Yup.string().required("Expires date is required"),
});
export const SubscriptionDetail = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { showToast, setIsFetching, setEmail } =
    useContext(AdminContext);
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
    title: EActionType.DELETE,
    content: <></>,
  });
  const [subscriptionDetail, setSubscriptionDetail] =
    useState<ISubscriptionRes>();

  const initialValues = {
    _id: subscriptionDetail?._id || "",
    user: subscriptionDetail?.user || "",
    email: subscriptionDetail?.email || "",
    subscriptionType:
      subscriptionDetail?.subscriptionType || ESubscriptionType.ADMIN,
    transactionId: subscriptionDetail?.transactionId || "",
    transactionDate: subscriptionDetail?.transactionDate || "",
    expiresDate: subscriptionDetail?.expiresDate || "",
    promo: subscriptionDetail?.promo || "",
    amount: subscriptionDetail?.amount || 0,
    productId: subscriptionDetail?.productId || "",
    description: subscriptionDetail?.description || "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: { ...initialValues },
    onSubmit: async (values) => {
      await handleUpdateSubscription(values);
    },
  });

  const {
    values,
    setFieldValue,
    errors,
    touched,
    dirty,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!userId) return;
    (async () => {
      try {
        setIsFetching(true);
        const res = await getCurrentSubscription(userId);
        setSubscriptionDetail(res);
        setEmail(res.email);
      } catch (error: any) {
        showToast("Error", error?.response?.data.message, EToastStatus.ERROR);
        if ((error as AxiosError)?.response?.status === 400) {
          navigate("/admin/subscription");
        }
      } finally {
        setIsFetching(false);
      }
    })();
  }, [userId]);

  const handleConfirm = (actionType: EActionType) => {
    setConfirmDialog({
      show: true,
      title: actionType,
      content: (
        <p>
          Are you sure you want to <strong>{actionType}</strong> this
          subscription?
        </p>
      ),
    });
  };

  const handleUpdateSubscription = async (value: ISubscriptionRes) => {
    try {
      setSubmitting(true);
      await updateSubscriptionInfo(value);
      showToast(
        "Success",
        "Update subscription successfully!",
        EToastStatus.SUCCESS
      );
      const res = await getCurrentSubscription(userId);
      setSubscriptionDetail(res);
      resetForm();
    } catch (error: any) {
      showToast("Error", error.response.data.message, EToastStatus.ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteSubscription = async () => {
    try {
      await deleteSubscription(userId);
      navigate(-1);
      showToast(
        "Success",
        "Subscription deleted successfully!",
        EToastStatus.SUCCESS
      );
    } catch (error) {
      showToast("Error", "Deleted subscription failed!", EToastStatus.ERROR);
    }
  };

  const handleConfirmAction = () => {
    setConfirmDialog({ ...confirmDialog, show: false });
    const actions: { [key: string]: () => void } = {
      [EActionType.DELETE]: handleDeleteSubscription,
    };
    const selectedAction = actions[confirmDialog.title];
    if (selectedAction) selectedAction();
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const {
      target: { name, value },
    } = e;
    if (name === ESubsFields.ExpiresDate) {
      const utcDateTime = moment(value).utc().format(EDateTimeFormat.UTC);
      return setFieldValue(name, utcDateTime);
    }
    setFieldValue(name, value);
  };

  return (
    <Card extra="w-full p-4 flex flex-col gap-5">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex-1 overflow-y-auto">
          <Flex gap={6}>
            <FormDetail.Input
              isDisabled
              flex={1}
              value={values.email}
              placeholder={subsField[ESubsFields.Email].label}
              name={ESubsFields.Email}
              onChange={handleInputChange}
            />
            <FormDetail.Input
              isDisabled
              placeholder={subsField[ESubsFields.TransactionId].label}
              name={ESubsFields.TransactionId}
              value={values.transactionId}
              onChange={handleInputChange}
            />
          </Flex>
          <Flex gap={6}>
            <FormDetail.Select
              flex={1}
              isDisabled
              placeholder={subsField[ESubsFields.SubscriptionType].label}
              name={ESubsFields.SubscriptionType}
              value={values.subscriptionType}
              onChange={handleInputChange}
            >
              {Object.values(ESubscriptionType).map((key) => (
                <option key={key} value={key}>
                  {SubscriptionTypeLabel[key]}
                </option>
              ))}
            </FormDetail.Select>
            <FormDetail.Input
              isDisabled
              placeholder={subsField[ESubsFields.ProductId].label}
              name={ESubsFields.ProductId}
              value={values.productId}
              onChange={handleInputChange}
            />
          </Flex>
          <FormDetail.Input
            isDisabled
            placeholder={subsField[ESubsFields.Amount].label}
            name={ESubsFields.Amount}
            value={values.amount}
            onChange={handleInputChange}
            errors={touched.amount && errors.amount}
            isInvalid={touched.amount && !!errors.amount}
            type="number"
          />
          <FormDetail.Input
            isDisabled
            placeholder={subsField[ESubsFields.Promo].label}
            name={ESubsFields.Promo}
            value={values.promo}
            onChange={handleInputChange}
          />
          <FormDetail.Input
            isDisabled
            showTimeZone
            placeholder={subsField[ESubsFields.TransactionDate].label}
            name={ESubsFields.TransactionDate}
            value={moment(values.transactionDate).format(
              EDateTimeFormat.FULL_DAY_WITH_TIME
            )}
            onChange={handleInputChange}
            type="datetime-local"
          />
          <FormDetail.Input
            showTimeZone
            placeholder={subsField[ESubsFields.ExpiresDate].label}
            name={ESubsFields.ExpiresDate}
            value={moment(values.expiresDate).format(
              EDateTimeFormat.FULL_DAY_WITH_TIME
            )}
            onChange={handleInputChange}
            errors={touched.expiresDate && errors.expiresDate}
            isInvalid={touched.expiresDate && !!errors.expiresDate}
            type="datetime-local"
          />
          <FormDetail.TextArea
            placeholder={subsField[ESubsFields.Description].label}
            name={ESubsFields.Description}
            value={values.description}
            onChange={handleInputChange}
          />
          <div className="flex flex-col gap-1 gap-2 p-2">
            <div className="flex items-center justify-between">
              <Button
                isLoading={isSubmitting}
                type="submit"
                isDisabled={!dirty}
                colorScheme="blue"
                boxShadow="md"
              >
                Save Changes
              </Button>
              <Button
                onClick={() => handleConfirm(EActionType.DELETE)}
                colorScheme="red"
                size="lg"
                padding={3}
                boxShadow="md"
              >
                <MdDelete size={35} />
              </Button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          showModal={confirmDialog.show}
          onAccept={handleConfirmAction}
          onDecline={() => setConfirmDialog({ ...confirmDialog, show: false })}
          title={confirmDialog.title}
          content={confirmDialog.content}
        />
      </form>
    </Card>
  );
};
