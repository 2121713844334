import { TagStatus } from "@components";
import { createColumnHelper } from "@tanstack/table-core";
import { capitalizeFirstLetter, formatPhoneNumber } from "@utils";
import { EDateTimeFormat, ESystemUser, systemUserFields } from "enums";
import moment from "moment";
import { ISystemUser } from "types";
import { cellClass, headerClass } from "views/share-class";

const columnHelper = createColumnHelper<ISystemUser>();

export const columnsSystemUser = [
  columnHelper.accessor(ESystemUser.FirstName, {
    id: ESystemUser.FirstName,
    header: () => (
      <p className={headerClass}>
        {systemUserFields[ESystemUser.FirstName].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESystemUser.LastName, {
    id: ESystemUser.LastName,
    header: () => (
      <p className={headerClass}>
        {systemUserFields[ESystemUser.LastName].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESystemUser.Email, {
    id: ESystemUser.Email,
    header: () => (
      <p className={headerClass}>{systemUserFields[ESystemUser.Email].label}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESystemUser.PhoneNumber, {
    id: ESystemUser.PhoneNumber,
    header: () => (
      <p className={headerClass}>
        {systemUserFields[ESystemUser.PhoneNumber].label}
      </p>
    ),
    cell: (info) => (
      <p className={cellClass}>{formatPhoneNumber(info.getValue())}</p>
    ),
  }),
  columnHelper.accessor(ESystemUser.CreatedTime, {
    id: ESystemUser.CreatedTime,
    header: () => (
      <p className={headerClass}>
        {systemUserFields[ESystemUser.CreatedTime].label}
      </p>
    ),
    cell: (info) => (
      <p className={cellClass}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(ESystemUser.SubRole, {
    id: ESystemUser.SubRole,
    header: () => (
      <p className={headerClass}>
        {systemUserFields[ESystemUser.SubRole].label}
      </p>
    ),
    cell: (info) => (
      <p className={cellClass}>{capitalizeFirstLetter(info.getValue())}</p>
    ),
  }),
  columnHelper.accessor(ESystemUser.Status, {
    id: ESystemUser.Status,
    header: () => (
      <p className={headerClass}>
        {systemUserFields[ESystemUser.Status].label}
      </p>
    ),
    cell: (info) => (
      <div className={cellClass}>{<TagStatus status={info.getValue()} />}</div>
    ),
  }),
];
