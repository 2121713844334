import { Button, Flex, Text } from "@chakra-ui/react";
import {
  Card,
  DateRangePicker,
  HeaderTable,
  Pagination,
  SearchInput,
  TableAdmin,
} from "@components";
import { AdminContext } from "context";
import {
  EDateParam,
  EOrder,
  EToastStatus,
  SubscriptionTypeLabel,
  subsField,
} from "enums";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { exportSubscriptionList, getSubscriptionList } from "services";
import { IPagable, ISortTable, ISubscriptionRes } from "types";
import { columnsSubscription } from "./components";
import { ESubsFields } from "enums";
import "./style.css";
import { PAGE_SIZE } from "@constants";

export const SubscriptionList = () => {
  const navigate = useNavigate();
  const { filterDate, setIsFetching, isFetching, showToast } =
    useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExporting, setIsExporting] = useState(false);
  const pageParam = searchParams.get("page") || "1";
  const searchParam = searchParams.get("search") || "";
  const [pageSubscription, setPageSubscription] =
    useState<IPagable<ISubscriptionRes[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "createdTime",
    order: EOrder.DESC,
  });
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [currentSubsCount, setCurrentSubsCount] = useState(0);
  const { result: listTable }: { result: ISubscriptionRes[] } =
    pageSubscription || { result: [] };
  const listTableChangeSort = listTable.map((item) => {
    return {
      ...item,
      [ESubsFields.SubscriptionType]:
        SubscriptionTypeLabel[item.subscriptionType],
    };
  });

  const exportText = useMemo(() => {
    const isFirstPage = Number(pageParam) === 1;
    return sortTable.orderBy === "createdTime" && isFirstPage
      ? "Export Most Recent"
      : "Export Shown";
  }, [sortTable.orderBy, pageParam]);

  useEffect(() => {
    (async () => {
      try {
        if (isNaN(parseInt(pageParam))) return navigate(`/admin/subscription`);
        setIsFetching(true);
        const currentParams = Object.fromEntries(searchParams.entries());
        const res = await getSubscriptionList(
          filterDate,
          decodeURIComponent(searchParam),
          parseInt(pageParam),
          sortTable,
          pageSize
        );
        if (!res) return;
        setPageSubscription(res);
        setCurrentSubsCount(res.result.length);
        res?.pageCount < parseInt(pageParam) &&
          setSearchParams({ ...currentParams, page: "1" });
      } catch (err: any) {
      } finally {
        setIsFetching(false);
      }
    })();
  }, [filterDate, searchParam, pageParam, sortTable, pageSize]);

  const onExportSubscriptions = async (exportAll = false) => {
    try {
      setIsExporting(true);
      const res = await exportSubscriptionList(
        filterDate,
        decodeURIComponent(searchParam),
        parseInt(pageParam),
        sortTable,
        exportAll
      );
      const url = window.URL.createObjectURL(
        new Blob([res], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "subscription-list.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsExporting(false);
    }
  };

  const handleSortUsers = (sort: ISortTable) => {
    if (!isFetching) {
      setSortTable(sort);
    }
  };

  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Subscription List"
        addonAfter={
          <>
            <Flex gap={3} wrap="wrap" justifyContent="start">
              <SearchInput />
              <DateRangePicker
                placeholder="Select start date"
                startParam={EDateParam.TRANSACT_START}
                endParam={EDateParam.TRANSACT_END}
              />
              <DateRangePicker
                placeholder="Select end date"
                startParam={EDateParam.EXPIRE_START}
                endParam={EDateParam.EXPIRE_END}
              />
            </Flex>
            <Flex gap={3} wrap="wrap" justifyContent="start">
              <Button
                isDisabled={isExporting || !listTableChangeSort?.length}
                onClick={() => onExportSubscriptions()}
              >
                <Flex flexWrap="wrap" display="flex" gap={1}>
                  {exportText}
                  <Text className="text-gray-700">({currentSubsCount})</Text>
                </Flex>
              </Button>
              <Button
                isDisabled={isExporting}
                onClick={() => onExportSubscriptions(true)}
              >
                Export All
              </Button>
            </Flex>
          </>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdmin
          listTable={listTableChangeSort}
          sortTable={sortTable}
          setSortTable={(sort) => handleSortUsers(sort)}
          columns={columnsSubscription}
          columnsConfig={subsField}
          pageSize={pageSize}
        />
        <Pagination<ISubscriptionRes>
          pageUsers={pageSubscription}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
    </Card>
  );
};
