import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal";
import { Flex, Button } from "@chakra-ui/react";
import { FormSub } from "@components";
import { createRandomUUID } from "@utils";
import { adminSubscriptionID } from "constants/subscription";
import { AdminContext } from "context";
import { EDateTimeFormat, ESubscriptionType, ESubsFields, EToastStatus } from "enums";
import moment from "moment-timezone";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createSubscription } from "services";
import { ISubscriptionRes } from "types";

export const ModalCreate = ({
  showModal,
  onDecline,
  data,
}: {
  showModal: boolean;
  onDecline: () => void;
  data?: ISubscriptionRes;
}) => {
  const cancelRef = useRef(null);
  const [fields, setFields] = useState<ISubscriptionRes>(data);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<ISubscriptionRes>();
  const { showToast } = useContext(AdminContext);
  const navigate = useNavigate();

  useEffect(() => {
    setFields({
      ...data,
      productId: adminSubscriptionID,
      subscriptionType: ESubscriptionType.ADMIN,
      transactionId: createRandomUUID(), 
      amount: 0,
    });
  }, [data]);

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === ESubsFields.ExpiresDate) {
      setError(null);
      const endDate = moment(value);
      const currentDateTime = endDate.isValid() ? endDate.utc().format(EDateTimeFormat.UTC) : "";
      setFields({ ...fields, [name]: currentDateTime });
      return;
    }
    setFields({ ...fields, [name]: value });
  };

  const handleCreateSubscription = async () => {
    if (!fields?.expiresDate) {
      return setError({...error, expiresDate: "Expires date is require!" })
    }
    try {
      setIsSubmitting(true);
      await createSubscription({...fields, transactionDate: new Date()});
      showToast(
        "Success",
        "Create subscription success!",
        EToastStatus.SUCCESS
      );
      navigate(0)
      onDecline();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      finalFocusRef={cancelRef}
      onClose={onDecline}
      isOpen={showModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex gap={6} flexDirection="column">
            <Flex gap={6}>
              <FormSub.Input
                readOnly
                name={ESubsFields.Email}
                value={fields?.email}
              />
              <FormSub.Input
                readOnly
                name={ESubsFields.ProductId}
                value={fields?.productId}
              />
            </Flex>
            <Flex gap={6}>
              <FormSub.Input
                readOnly
                name={ESubsFields.TransactionId}
                value={fields?.transactionId}
                onChange={handleChangeInput}
              />
            </Flex>
            <Flex gap={6}>
              <FormSub.Input
                name={ESubsFields.ExpiresDate}
                showTimeZone
                type="datetime-local"
                value={moment(fields?.expiresDate).format(EDateTimeFormat.FULL_DAY_WITH_TIME)}
                onChange={handleChangeInput}
                error={error?.expiresDate}
              />
              <FormSub.Input
                readOnly
                name={ESubsFields.Amount}
                value={fields?.amount}
                type="number"
                onChange={handleChangeInput}
              />
            </Flex>
            <FormSub.TextArea
              name={ESubsFields.Description}
              value={fields?.description}
              onChange={handleChangeInput}
            />
          </Flex>
        </ModalBody>
        <ModalFooter gap={5}>
          <Button
            isLoading={isSubmitting}
            isDisabled={!!error}
            colorScheme="purple"
            onClick={handleCreateSubscription}
          >
            Create
          </Button>
          <Button ref={cancelRef} onClick={() => onDecline()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};