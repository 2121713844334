import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { ConfirmDialog, PartnerFormSub, SelectPartner } from "@components";
import { AdminContext } from "context";
import {
  EActionType,
  ECodeFields,
  EDateTimeFormat,
  EOtherFields,
  EToastStatus,
} from "enums";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  createCode,
  deleteCode,
  getCodeDetail,
  updateCode,
} from "services";
import { ICodeRes } from "types";
import * as Yup from "yup";
interface CodeModalProps {
  showModal: boolean;
  onDecline: () => void;
  data?: ICodeRes;
  action?: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().when(ECodeFields.Quantity, (quantity: any, schema) => {
    return quantity > 1
      ? schema.notRequired()
      : schema.required(`Name is required`);
  }),
  maxUses: Yup.number()
    .required("Max uses is required")
    .min(1, "Max uses must be greater than 0"),
  quantity: Yup.number()
    .required("Quantity is required")
    .min(1, "Quantity must be greater than 0"),
  duration: Yup.number()
    .required("Duration is required")
    .min(1, "Duration must be greater than 0"),
  startTime: Yup.date().required("Start time is required"),
  endTime: Yup.date()
    .required("End time is required")
    .when(ECodeFields.StartDate, (startTime: any, schema: any) => {
      return schema.min(startTime, "End time must be greater than start time");
    }),
});

export const CodeModal = ({
  showModal,
  onDecline,
  data,
  action,
}: CodeModalProps) => {
  const cancelRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [codeId, setCodeId] = useState("");
  const [isDisableDelete, setIsDisableDelete] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
    title: EActionType.DELETE,
    content: <>Do you want to {EActionType.DELETE} this Code?</>,
  });
  let initialValues = {
    name: "",
    maxUses: 1,
    quantity: 1,
    duration: 1,
    partner: "",
    startTime: moment(new Date()).format(EDateTimeFormat.FULL_DAY_WITH_TIME),
    endTime: moment(new Date()).format(EDateTimeFormat.FULL_DAY_WITH_TIME),
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      handleUpdateCode();
    },
  });
  const {
    values,
    setFieldValue,
    handleChange,
    errors,
    isValid,
    resetForm,
    handleSubmit,
  } = formik;
  const { showToast } = useContext(AdminContext);

  const handleAddCode = async () => {
    try {
      setIsSubmitting(true);
      await createCode({
        ...values,
        [ECodeFields.Quantity]: Number(values.quantity),
        [ECodeFields.StartDate]: new Date(values.startTime),
        [ECodeFields.EndDate]: new Date(values.endTime)
      });
      showToast("Success", "Create Code success!", EToastStatus.SUCCESS);
      action();
      onDecline();
      resetForm();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsDisableDelete(true);
      if (!data) return;
      try {
        formik.resetForm({
          values: {
            [ECodeFields.Partner]: data.partner,
            [ECodeFields.Duration]: data.duration,
            [ECodeFields.MaxUses]: data.maxUses,
            [ECodeFields.StartDate]: moment(data.startTime).format(
              EDateTimeFormat.FULL_DAY_WITH_TIME
            ),
            [ECodeFields.EndDate]: moment(data.endTime).format(
              EDateTimeFormat.FULL_DAY_WITH_TIME
            ),
            [ECodeFields.Name]: data.name,
            [ECodeFields.Quantity]: 1,
          },
        });
        setCodeId(data._id);
        const getCodeResult = await getCodeDetail(data._id);
        if (!getCodeResult) return;
        setIsDisableDelete(getCodeResult?.isUsed);
      } catch (err) { }
    })();
  }, [data, showModal]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const type = e?.target?.name;
    switch (type) {
      case ECodeFields.StartDate:
        setFieldValue(ECodeFields.StartDate, value);
        if (value > String(values.endTime)) {
          setFieldValue(ECodeFields.EndDate, value);
        }
        break;
      case ECodeFields.EndDate:
        setFieldValue(ECodeFields.EndDate, value);
        break;
      case ECodeFields.Quantity:
        if (+value > 1) {
          setFieldValue(ECodeFields.MaxUses, 1);
          setFieldValue(ECodeFields.Name, "");
        }
        setFieldValue(ECodeFields.Quantity, value);
        break;
      default:
    }
  };

  const handlePartnerChange = (e: { value: string; label: string }) => {
    const value = e.value;
    formik.setFieldValue(ECodeFields.Partner, value);
  };

  const handleUpdateCode = async () => {
    try {
      setIsSubmitting(true);
      await updateCode({
        ...values,
        [ECodeFields.Id]: codeId,
        [ECodeFields.Quantity]: Number(values.quantity),
        [ECodeFields.StartDate]: new Date(values.startTime),
        [ECodeFields.EndDate]: new Date(values.endTime)
      });
      action();
      onDecline();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirmAction = () => {
    const actions: { [key: string]: (id: string) => void } = {
      [EActionType.DELETE]: handleDeleteCode,
    };
    const selectedAction = actions[confirmDialog.title];
    if (selectedAction) selectedAction(codeId);
  };

  const handleDeleteCode = async () => {
    try {
      await deleteCode(codeId);
      showToast("Success", "Delete Code successfully!", EToastStatus.SUCCESS);
      action();
      onDecline();
      setConfirmDialog((prev) => {
        return { ...prev, show: false };
      });
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
      setConfirmDialog((prev) => {
        return { ...prev, show: false };
      });
    }
  };

  function handleChangeConfirmDialog() {
    setConfirmDialog((prev) => {
      return { ...prev, show: true };
    });
    setCodeId(data?._id);
  }

  return (
    <Modal
      finalFocusRef={cancelRef}
      onClose={() => {
        onDecline();
        resetForm();
      }}
      isOpen={showModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent onSubmit={handleSubmit}>
        <ModalHeader>{data ? "Edit Code" : "Create new code"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex gap={6} flexDirection="column">
            <Flex gap={6}>
              <PartnerFormSub.Input
                isDisabled={+values?.quantity > 1}
                name={ECodeFields.Name}
                value={values.name}
                onChange={handleChange}
                error={errors.name}
              />
            </Flex>
            <Flex gap={6}>
              <FormControl>
                <FormLabel display="flex" alignItems="center" gap={2}>
                  Partner
                </FormLabel>
                <SelectPartner
                  handlePartnerChange={handlePartnerChange}
                  data={data}
                />
              </FormControl>
            </Flex>
            <Flex gap={6}>
              <PartnerFormSub.Input
                isDisabled={+values?.quantity > 1}
                type="number"
                name={ECodeFields.MaxUses}
                value={values.maxUses}
                onChange={handleChange}
                error={errors.maxUses}
              />
              <PartnerFormSub.Input
                isDisabled={data}
                type="number"
                name={EOtherFields.QTY}
                value={values.quantity}
                onChange={handleInputChange}
                error={errors.quantity}
              />
              <PartnerFormSub.Input
                type="number"
                name={ECodeFields.Duration}
                value={values.duration}
                onChange={handleChange}
                error={errors.duration}
              />
            </Flex>
            <Flex gap={6}>
              <PartnerFormSub.Input
                showTimeZone
                name={ECodeFields.StartDate}
                type="datetime-local"
                value={moment(values?.startTime).format(
                  EDateTimeFormat.FULL_DAY_WITH_TIME
                )}
                onChange={handleInputChange}
                error={errors.startTime}
              />
            </Flex>
            <Flex gap={6}>
              <PartnerFormSub.Input
                showTimeZone
                name={ECodeFields.EndDate}
                type="datetime-local"
                value={moment(values?.endTime).format(
                  EDateTimeFormat.FULL_DAY_WITH_TIME
                )}
                onChange={handleInputChange}
                min={values?.startTime}
                error={errors.endTime}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter gap={5}>
          {data ? (
            <Button
              isLoading={false}
              colorScheme="red"
              onClick={handleChangeConfirmDialog}
              isDisabled={isDisableDelete}
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
          <Button
            isLoading={isSubmitting}
            isDisabled={!values?.partner || !isValid}
            colorScheme="purple"
            onClick={data ? handleUpdateCode : handleAddCode}
          >
            {data ? "Save" : "Generate"}
          </Button>
        </ModalFooter>
      </ModalContent>
      <ConfirmDialog
        showModal={confirmDialog.show}
        onAccept={handleConfirmAction}
        onDecline={() => setConfirmDialog({ ...confirmDialog, show: false })}
        title={confirmDialog.title}
        content={confirmDialog.content}
      />
    </Modal>
  );
};
