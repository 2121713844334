import { Button, Flex } from "@chakra-ui/react";
import {
  Card,
  DateRangePicker,
  HeaderTable,
  Pagination,
  SearchInput,
  TableAdmin,
} from "@components";
import { PAGE_SIZE } from "@constants";
import { AdminContext } from "context";
import { EDateParam, EOrder } from "enums";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSystemUserList } from "services";
import { IPagable, ISortTable, ISystemUser } from "types";
import { AddSystemUserModal, columnsSystemUser } from "./components";

export const SystemUserList = () => {
  const navigate = useNavigate();
  const { filterDate, setIsFetching, isFetching } = useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = useMemo(() => {
    return searchParams.get("page") || "1";
  }, [searchParams]);
  const searchParam = useMemo(() => {
    return searchParams.get("search") || "";
  }, [searchParams]);
  const [systemUsers, setSystemUsers] = useState<IPagable<ISystemUser[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "firstName",
    order: EOrder.ASC,
  });
  const [openModalAddSystemUser, setOpenModalAddSystemUser] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const getSystemUsers = useCallback(async () => {
    if (isNaN(+pageParam)) return navigate(`/admin/employers`);
    const currentParams = Object.fromEntries(searchParams.entries());
    const res = await getSystemUserList(
      filterDate,
      decodeURIComponent(searchParam),
      +pageParam,
      sortTable
    );
    setSystemUsers(res);
    if (res?.pageCount < +pageParam) {
      setSearchParams({ ...currentParams, page: "1" });
    }
  }, [pageParam, searchParams, filterDate, searchParam, sortTable]);

  useEffect(() => {
    (async () => {
      try {
        if (isNaN(parseInt(pageParam)))
          return navigate(`/admin/user/system-users`);
        setIsFetching(true);
        const currentParams = Object.fromEntries(searchParams.entries());
        const res = await getSystemUserList(
          filterDate,
          decodeURIComponent(searchParam),
          parseInt(pageParam),
          sortTable,
          pageSize
        );
        setSystemUsers(res);
        if (res?.pageCount < parseInt(pageParam)) {
          setSearchParams({ ...currentParams, page: "1" });
        }
      } finally {
        setIsFetching(false);
      }
    })();
  }, [filterDate, searchParam, pageParam, sortTable, pageSize]);

  const handleSortUsers = (sort: ISortTable) => {
    if (isFetching) return;
    setSortTable(sort);
  };

  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="System User List"
        addonAfter={
          <Flex gap={4} wrap="wrap" justifyContent="start">
            <SearchInput />
            <DateRangePicker
              placeholder="Select created date"
              startParam={EDateParam.START}
              endParam={EDateParam.END}
            />
            <Button onClick={() => setOpenModalAddSystemUser(true)}>
              New System User
            </Button>
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdmin
          listTable={systemUsers?.result ?? []}
          sortTable={sortTable}
          setSortTable={handleSortUsers}
          columns={columnsSystemUser}
          pageSize={pageSize}
        />
        <Pagination<ISystemUser>
          pageUsers={systemUsers}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
      <AddSystemUserModal
        showModal={openModalAddSystemUser}
        onDecline={() => setOpenModalAddSystemUser(false)}
        data={null}
        action={getSystemUsers}
      />
    </Card>
  );
};
