import { Menu, MenuButton, Button, MenuList, MenuItem } from '@chakra-ui/react';
interface StatusDropDownType {
  header: string;
  status: boolean;
  listDropdown: string[];
  onchange: (data: object) => void;
}
export const StatusDropDown = ({
  header,
  status,
  listDropdown,
  onchange,
}: StatusDropDownType): JSX.Element => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        className={
          status
            ? 'w-[110px] !bg-green-100 text-green-200 '
            : 'w-[110px] !bg-red-100 text-red-200'
        }
      >
        {header}
      </MenuButton>
      <MenuList className="w-[110px] !min-w-0 overflow-hidden">
        {listDropdown.map((item: string, index: number ) => (
          <MenuItem
            key={index}
            onClick={() => {
              onchange({ status: item.toLowerCase() });
            }}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
