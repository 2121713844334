import { Input, InputGroup, InputLeftElement, InputProps } from "@chakra-ui/react";
import { AdminContext } from "context";
import { useContext, useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

const inputSearchClass = ["w-full sm:w-[250px]"];

export const SearchInput = (props: InputProps) => {
  const { isFetching } = useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = searchParams.get("search") || "";
  const [search, setSearch] = useState(decodeURIComponent(searchParam));
  const [debouncedSearch] = useDebounce(search, 500);
  const [pendingSearch, setPendingSearch] = useState<string | null>(null);

  useEffect(() => {
    if (!isFetching && pendingSearch !== null) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        search: encodeURIComponent(pendingSearch)
      });
      setSearch(pendingSearch);
      setPendingSearch(null);
    }
  }, [isFetching, pendingSearch, searchParams, setSearchParams]);

  useEffect(() => {
    setPendingSearch(debouncedSearch);
  }, [debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <div className={inputSearchClass.join("")}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IoSearchOutline />
        </InputLeftElement>
        <Input
          value={search}
          size="md"
          onChange={handleInputChange}
          type="search"
          placeholder={props.placeholder || "Search"}
          variant="outline"
          focusBorderColor="purple.500"
          className="bg-white dark:bg-navy-600 dark:text-white"
          {...props}
        />
      </InputGroup>
    </div>
  );
};
