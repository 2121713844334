import { Button, Switch, Text } from "@chakra-ui/react";
import { Card, ConfirmDialog, FormDetail } from "@components";
import { formatPhoneNumber, parsePhoneNumber } from "@utils";
import { AxiosError } from "axios";
import { AdminContext } from "context";
import {
  EActionType,
  ESystemUser,
  ESubRole,
  EToastStatus,
  EUserFields,
  UserStatus,
  phoneNumberUSRegex,
  systemUserFields,
  userFieldLabel,
} from "enums";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteUserById,
  getUserById,
  updateAvatar,
  updateUserInfo,
} from "services";
import { EValuesUpdateUser, IUserDetailRes } from "types";
import * as Yup from "yup";
import { UserAvatar } from "./components";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(50, "First Name must not be longer than 50 characters")
    .trim(),
  lastName: Yup.string()
    .required("Last name is required")
    .max(50, "Last Name must not be longer than 50 characters")
    .trim(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneNumberUSRegex, "Invalid phone number"),
});

export const UserDetail = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { showToast, setIsFetching, setEmail } = useContext(AdminContext);
  const [userDetail, setUserDetail] = useState<IUserDetailRes>();
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
    title: EActionType.DELETE,
    content: <></>,
  });
  const [previewAvatar, setPreviewAvatar] = useState<string>("");

  const initialValues = {
    _id: userDetail?._id || "",
    avatar: userDetail?.avatar || "",
    firstName: userDetail?.firstName || "-",
    lastName: userDetail?.lastName || "-",
    email: userDetail?.email || "-",
    phoneNumber: userDetail?.phoneNumber || "-",
    status: userDetail?.status || UserStatus.ACTIVE,
    subRole: userDetail?.subRole,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: { ...initialValues },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        const updateAvatarPromise = updateUserProfileAvatar(values);
        const updateUserInfoPromise = updateUserProfileInfo(values);
        await Promise.all([updateAvatarPromise, updateUserInfoPromise]);

        resetForm();
        setPreviewAvatar("");
        const updatedUser = await getUserById(userId);
        setUserDetail(updatedUser);
      } catch (error) {
        console.log("log-error --- ", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, setFieldValue, errors, touched, dirty, isSubmitting } =
    formik;

  useEffect(() => {
    if (userId) {
      (async () => {
        try {
          setIsFetching(true);
          const res = await getUserById(userId);
          setUserDetail(res);
          setEmail(res.email);
        } catch (error) {
          console.log("log-error --- ", error);
          showToast("Error", "User not found!", EToastStatus.ERROR);
          if ((error as AxiosError)?.response?.status === 400) {
            navigate("/admin/users");
          }
        } finally {
          setIsFetching(false);
        }
      })();
    }
  }, [userId]);

  const activeStatus = useMemo(() => {
    return values.status === UserStatus.ACTIVE;
  }, [values.status]);

  const handleConfirm = (actionType: EActionType) => {
    setConfirmDialog({
      show: true,
      title: actionType,
      content: (
        <p>
          Are you sure you want to <strong>{actionType}</strong> this user?
        </p>
      ),
    });
  };

  const deleteUserProfile = async () => {
    try {
      await deleteUserById(userId);
      navigate(-1);
      showToast("Success", "User deleted successfully!", EToastStatus.SUCCESS);
    } catch (error) {
      console.log("log-error --- ", error);
      showToast("Error", "User deleted failed!", EToastStatus.ERROR);
    }
  };

  const updateUserProfileAvatar = async (values: EValuesUpdateUser) => {
    try {
      if (previewAvatar && values.avatar) {
        await updateAvatar(userId, values.avatar);
        showToast(
          "Success",
          "Update avatar successfully!",
          EToastStatus.SUCCESS
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.code === 400) {
        console.log("log-error --- ", error);
        return showToast(
          "Error",
          error.response.data.message,
          EToastStatus.ERROR
        );
      }
      showToast("Error", "Update avatar failed!", EToastStatus.ERROR);
      console.log("log-error --- ", error);
    }
  };

  const updateUserProfileInfo = async (values: EValuesUpdateUser) => {
    try {
      const isChanged = Object.keys(userDetail).some(
        (key) =>
          key !== "avatar" &&
          userDetail[key] !== values[key as keyof typeof values]
      );
      if (isChanged) {
        await updateUserInfo(userId, values);
        showToast("Success", "Update user successfully!", EToastStatus.SUCCESS);
      }
    } catch (error: any) {
      if (error?.response?.data?.code === 400) {
        console.log("log-error --- ", error);
        return showToast(
          "Error",
          error.response.data.message,
          EToastStatus.ERROR
        );
      }
      showToast("Error", "Update user failed!", EToastStatus.ERROR);
      console.log("log-error --- ", error);
    }
  };

  const handleConfirmAction = () => {
    setConfirmDialog({ ...confirmDialog, show: false });
    const actions: { [key: string]: () => void } = {
      [EActionType.DELETE]: deleteUserProfile,
    };
    const selectedAction = actions[confirmDialog.title];
    if (selectedAction) selectedAction();
  };

  const handleInputChange = (fieldName: EUserFields, value: string | File) => {
    if (fieldName === EUserFields.Avatar) {
      const isImage = (value as File).type.split("/")[0] === "image";
      if (!isImage) {
        return showToast(
          "Error",
          <>
            <p>The file format you are trying to upload is not supported!</p>
            Supported images types:{" "}
            <strong>jpg, png, bmp, heic, webp, jpeg</strong>
          </>,
          EToastStatus.ERROR
        );
      }
      setPreviewAvatar(URL.createObjectURL(value as File));
    }
    setFieldValue(fieldName, value);
  };

  const handleRoleChange = (fieldName: ESystemUser, value: string) => {
    setFieldValue(fieldName, value);
  };

  return (
    <Card extra="w-full p-4 flex flex-col gap-5">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-between md:gap-5">
          <UserAvatar
            firstName={userDetail?.firstName}
            lastName={userDetail?.lastName}
            avatar={previewAvatar || values.avatar}
            setNewAvatar={(file: File) => {
              handleInputChange(EUserFields.Avatar, file);
            }}
          />
          <Button
            onClick={() => handleConfirm(EActionType.DELETE)}
            colorScheme="red"
            size="lg"
            padding={3}
            boxShadow="md"
          >
            <MdDelete size={35} />
          </Button>
        </div>
        <div className="flex-1 overflow-y-auto">
          <FormDetail.Input
            placeholder={userFieldLabel[EUserFields.FirstName]}
            value={values.firstName}
            onChange={(e) =>
              handleInputChange(EUserFields.FirstName, e.target.value)
            }
            errors={touched.firstName && errors.firstName}
            isInvalid={touched.firstName && !!errors.firstName}
          />
          <FormDetail.Input
            placeholder={userFieldLabel[EUserFields.LastName]}
            value={values.lastName}
            onChange={(e) =>
              handleInputChange(EUserFields.LastName, e.target.value)
            }
            errors={touched.lastName && errors.lastName}
            isInvalid={touched.lastName && !!errors.lastName}
          />
          <FormDetail.Input
            placeholder={userFieldLabel[EUserFields.Email]}
            value={values.email}
            onChange={(e) =>
              handleInputChange(EUserFields.Email, e.target.value)
            }
            errors={touched.email && errors.email}
            isInvalid={touched.email && !!errors.email}
          />
          <FormDetail.Input
            placeholder={userFieldLabel[EUserFields.PhoneNumber]}
            value={formatPhoneNumber(values.phoneNumber)}
            onChange={(e) =>
              handleInputChange(
                EUserFields.PhoneNumber,
                parsePhoneNumber(e.target.value)
              )
            }
            errors={touched.phoneNumber && errors.phoneNumber}
            isInvalid={touched.phoneNumber && !!errors.phoneNumber}
          />
          {values.subRole && (
            <FormDetail.Select
              placeholder={systemUserFields[ESystemUser.SubRole].label}
              value={values.subRole}
              onChange={(e) =>
                handleRoleChange(ESystemUser.SubRole, e.target.value)
              }
              errors={touched.subRole && errors.subRole}
              isInvalid={touched.subRole && !!errors.subRole}
            >
              <option value={ESubRole.EMPLOYER}>Employer</option>
              <option value={ESubRole.ADMIN}>Admin</option>
            </FormDetail.Select>
          )}
          <div className="flex flex-col gap-1 gap-2 p-2">
            <div className="text-md break-words font-bold text-navy-700 dark:text-white md:text-lg">
              Status
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Switch
                  size="lg"
                  isChecked={activeStatus}
                  colorScheme="green"
                  onChange={() =>
                    handleInputChange(
                      EUserFields.Status,
                      activeStatus ? UserStatus.INACTIVE : UserStatus.ACTIVE
                    )
                  }
                />
                <Text
                  as="b"
                  fontSize="md"
                  className="capitalize"
                  color={activeStatus ? "green.500" : "gray.400"}
                >
                  {values.status}
                </Text>
              </div>
              <Button
                isLoading={isSubmitting}
                type="submit"
                isDisabled={!dirty}
                colorScheme="blue"
                boxShadow="md"
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          showModal={confirmDialog.show}
          onAccept={handleConfirmAction}
          onDecline={() => setConfirmDialog({ ...confirmDialog, show: false })}
          title={confirmDialog.title}
          content={confirmDialog.content}
        />
      </form>
    </Card>
  );
};

