import { OTP_LENGTH } from "@constants";

const formatNickname = (value = "") => {
  const regex = /^[A-Z0-9_.]+$/;
  return value
    .toUpperCase()
    .split("")
    .filter((char) => regex.test(char))
    .join("");
};

const isValidOtp = (otp: string) => otp.length === OTP_LENGTH && /^\d+$/.test(otp);

export { formatNickname, isValidOtp };
