import { useToast } from "@chakra-ui/react";
import {
  calculateTokenRefreshDelay,
  getToken,
  removeToken,
  storeAccessTokens,
} from "@utils";
import { EToastStatus, dateParamsQuery } from "enums";
import moment from "moment";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRefreshTokenService } from "services";
import { IFilterDate, IGlobalContext } from "types";

export const AdminContext = createContext<IGlobalContext>({} as IGlobalContext);

export const AdminContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const [email, setEmail] = useState("");
  const [filterDate, setFilterDate] = useState<IFilterDate>(dateParamsQuery);
  const [isBackDropLoading, setIsBackDropLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const toast = useToast();

  const isAuthorizedPath: boolean = useMemo(
    () => location.pathname.split("/")?.[1] === "admin",
    [location.pathname]
  );

  const currentTimezone = useMemo(() => {
    const localTimezone = moment.tz.guess();
    const timezone = moment.tz.zone(localTimezone);
    const currentTimezoneName = timezone?.name || "";
    const offsetInMinutes = moment.tz(localTimezone).utcOffset();
    const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
    const offsetMinutes = Math.abs(offsetInMinutes % 60);
    const offsetSign = offsetInMinutes >= 0 ? "+" : "-";
    const offsetString = `UTC${offsetSign}${offsetHours}:${offsetMinutes
      .toString()
      .padStart(2, "0")}`;
    return { name: currentTimezoneName, timezone: offsetString };
  }, []);

  const showToast = (
    title: React.ReactNode,
    description: React.ReactNode,
    status: EToastStatus
  ) => {
    toast({
      title,
      description,
      status,
      position: "top-right",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleUnauthorized = useCallback(() => {
    removeToken("AccessToken");
    removeToken("RefreshToken");
    navigate("/auth");
  }, [navigate]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleRefreshToken = async () => {
      try {
        const tokens = await getRefreshTokenService();
        storeAccessTokens(tokens);
        setIsInitialized(true);
        scheduleTokenRefresh();
      } catch (err: any) {
        handleUnauthorized();
        showToast("Error", err?.response?.data?.message, EToastStatus.ERROR);
      }
    };

    const scheduleTokenRefresh = async () => {
      const accessToken = getToken("AccessToken");
      if (!accessToken) {
        handleUnauthorized();
        return;
      }

      const diffInMilliseconds = calculateTokenRefreshDelay();

      if (diffInMilliseconds <= 0) {
        handleRefreshToken();
      } else {
        timeoutId = setTimeout(() => {
          handleRefreshToken();
        }, diffInMilliseconds);

        setIsInitialized(true);
      }
    };

    if (isAuthorizedPath) {
      scheduleTokenRefresh();
    } else {
      setIsInitialized(true);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isAuthorizedPath]);

  return (
    <AdminContext.Provider
      value={{
        email,
        setEmail,
        showToast,
        isFetching,
        setIsFetching,
        filterDate,
        setFilterDate,
        currentTimezone,
        setIsBackDropLoading,
        isBackDropLoading,
        handleUnauthorized,
        isInitialized,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
