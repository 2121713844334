import { ColumnDef, createColumnHelper } from "@tanstack/table-core";
import { EDateTimeFormat, ESubsFields, subsField } from "enums";
import moment from "moment";
import { FaPen, FaPlus } from "react-icons/fa";
import { ISubscriptionRes } from "types";
import { cellClass, headerClass } from "views/share-class";

const columnHelper = createColumnHelper<ISubscriptionRes>();

export const columnsSubscription: ColumnDef<ISubscriptionRes, any>[] = [
  columnHelper.accessor(ESubsFields.Email, {
    id: ESubsFields.Email,
    header: () => (
      <p className={headerClass}>{subsField[ESubsFields.Email].label}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESubsFields.SubscriptionType, {
    id: ESubsFields.SubscriptionType,
    header: () => (
      <p className={headerClass}>
        {subsField[ESubsFields.SubscriptionType].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESubsFields.ProductId, {
    id: ESubsFields.ProductId,
    header: () => (
      <p className={headerClass}>{subsField[ESubsFields.ProductId].label}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESubsFields.TransactionId, {
    id: ESubsFields.TransactionId,
    header: () => (
      <p className={headerClass}>
        {subsField[ESubsFields.TransactionId].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESubsFields.Amount, {
    id: ESubsFields.Amount,
    header: () => (
      <p className={headerClass}>{subsField[ESubsFields.Amount].label}</p>
    ),
    cell: (info) => <p className={cellClass}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESubsFields.Promo, {
    id: ESubsFields.Promo,
    header: () => (
      <p className={headerClass}>{subsField[ESubsFields.Promo].label}</p>
    ),
    cell: (info) => <p className={cellClass}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ESubsFields.TransactionDate, {
    id: ESubsFields.TransactionDate,
    header: () => (
      <p className={headerClass}>
        {subsField[ESubsFields.TransactionDate].label}
      </p>
    ),
    cell: (info) => (
      <p className={cellClass}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(ESubsFields.ExpiresDate, {
    id: ESubsFields.ExpiresDate,
    header: () => (
      <p className={headerClass}>
        {subsField[ESubsFields.ExpiresDate].label}
      </p>
    ),
    cell: (info) => (
      <p className={cellClass}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(ESubsFields.ExpiresDate, {
    id: "Actions",
    header: () => <p className={headerClass}>Actions</p>,
    cell: (info) =>
      <div className="dark:text-white flex justify-center">
        {!info.row.original._id ? <FaPlus /> : <FaPen />}
      </div>
  }),
];