import { StylesConfig } from "react-select";

export const customStyles: StylesConfig<any, boolean> = {
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: "#ffffff",
    borderColor: state.isFocused ? "#805AD5" : "#E2E8F0",
    boxShadow: state.isFocused ? "0 0 0 1px #805AD5" : "none",
    minHeight: 40,
    "&:hover": {
      borderColor: "#805AD5",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007bff"
      : state.isFocused
      ? "#e9ecef"
      : "#ffffff",
    color: state.isSelected ? "#ffffff" : "#000000",
    "&:hover": {
      backgroundColor: "#e9ecef",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#a0aec0",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#495057",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e9ecef",
    color: "#495057",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#495057",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#007bff",
    "&:hover": {
      backgroundColor: "#d1ecf1",
      color: "#0056b3",
    },
  }),
};
