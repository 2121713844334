import { ColumnDef, createColumnHelper } from "@tanstack/table-core";
import { EDateTimeFormat, ERedemptions, redemptionField } from "enums";
import moment from "moment";
import { IRedemptionRes } from "types";
import { cellClass, headerClass } from "views/share-class";

const columnHelper = createColumnHelper<IRedemptionRes>();

export const columnsRedemption: ColumnDef<IRedemptionRes, any>[] = [
  columnHelper.accessor(ERedemptions.Email, {
    id: ERedemptions.Email,
    header: () => (
      <p className={headerClass}>{redemptionField[ERedemptions.Email].label}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ERedemptions.Code, {
    id: ERedemptions.Code,
    header: () => (
      <p className={headerClass}>
        {redemptionField[ERedemptions.Code].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ERedemptions.Partner, {
    id: ERedemptions.Partner,
    header: () => (
      <p className={headerClass}>{redemptionField[ERedemptions.Partner].label}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ERedemptions.Duration, {
    id: ERedemptions.Duration,
    header: () => (
      <p className={headerClass}>
        {redemptionField[ERedemptions.Duration].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(ERedemptions.TransactionDate, {
    id: ERedemptions.TransactionDate,
    header: () => (
      <p className={headerClass}>{redemptionField[ERedemptions.TransactionDate].label}</p>
    ),
    cell: (info) => <p className={cellClass}>{info.getValue() &&
      moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}</p>,
  }),
  columnHelper.accessor(ERedemptions.TransactionId, {
    id: ERedemptions.TransactionId,
    header: () => (
      <p className={headerClass}>{redemptionField[ERedemptions.TransactionId].label}</p>
    ),
    cell: (info) => <p className={cellClass}>{info.getValue()}</p>,
  }),
];