import { createColumnHelper } from "@tanstack/table-core";
import { EDateTimeFormat,EPartnerFields, partnerFieldLabel } from "enums";
import moment from "moment";
import { IPartnerRes } from "types";
import { cellClass, headerClass } from "views/share-class";

const columnHelper = createColumnHelper<IPartnerRes>();
export const columnsPartners = [
  columnHelper.accessor(EPartnerFields.Name, {
    id: EPartnerFields.Name,
    header: () => (
      <p className={headerClass}>{partnerFieldLabel[EPartnerFields.Name]}</p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EPartnerFields.CreatedTime, {
    id: EPartnerFields.CreatedTime,
    header: () => (
      <p className={headerClass}>
        {partnerFieldLabel[EPartnerFields.CreatedTime]}
      </p>
    ),
    cell: (info) => (
      <p className={`${cellClass}`}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(EPartnerFields.Status, {
    id: EPartnerFields.Status,
    header: () => (
      <p className={headerClass}>{partnerFieldLabel[EPartnerFields.Status]}</p>
    ),
    cell: (info) => <div className={cellClass}>{info.getValue()}</div>,
  }),
];
