import { FC, useContext, useMemo, useState } from "react";
import { Button, Flex, Tooltip } from "@chakra-ui/react";
import Select, { SingleValue } from "react-select";
import { useSearchParams } from "react-router-dom";
import { FaPlus, FaLink } from "react-icons/fa";

import { DateRangePicker, SearchInput } from "@components";
import { EDateParam, EToastStatus } from "enums";
import { AdminContext } from "context";
import { exportEligibleEmployees } from "services/eligible-employee";
import { ImportModal } from "./ImportModal";
import { IEmployer } from "types";
import { customStyles } from "@utils";
import { useEligibleEmployeesContext } from "..";

interface IProps {
  onClickAdd: () => void;
}

export const Toolbar: FC<IProps> = ({ onClickAdd }) => {
  const { showToast } = useContext(AdminContext);
  const { employers, handleForceUpdateList } = useEligibleEmployeesContext();

  const [showModalImport, setShowModalImport] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const employerId = useMemo(() => searchParams.get("employerId") || "", [searchParams]);

  const employerSelected = useMemo(() => (
    employers.find(i => i._id === employerId)
  ), [employerId, employers]);

  const handleChangeEmployees = (newValue: SingleValue<IEmployer>) => {
    const value = newValue?._id || '';
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      employerId: encodeURIComponent(value),
    });
  };

  const handleExport = async () => {
    setIsLoading(true);

    try {
      const downloadUrl = await exportEligibleEmployees(employerId);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "employees.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImport = () => {
    setShowModalImport(true);
  };

  const handleClose = (isReload: boolean) => {
    setShowModalImport(false);
    if (isReload) {
      handleForceUpdateList();
    }
  };

  return (
    <>
      <Flex justifyContent="space-between" className="3xl:flex-row flex-col gap-4">
        <Flex gap={4} wrap="wrap" justifyContent="start">
          <SearchInput />
          <DateRangePicker
            placeholder="Select Date"
            startParam={EDateParam.START}
            endParam={EDateParam.END}
          />
          <div className="sm:w-[250px]">
            <Select
              styles={customStyles}
              placeholder="Select Employer"
              value={employerSelected}
              isMulti={false}
              isClearable
              onChange={handleChangeEmployees}
              options={employers}
              getOptionLabel={(employer: IEmployer) => employer.employerName}
              getOptionValue={(employer: IEmployer) => employer._id}
            />
          </div>
        </Flex>
        <Flex gap={4} wrap="wrap" justifyContent="start">
          <Tooltip label="Copy Redemption Page URL">
            <div className="flex items-center gap-2 cursor-pointer hover:text-blue-500 duration-300" onClick={() => {
              navigator.clipboard.writeText(process.env.REACT_APP_REDEMPTION_PAGE_URL || '');
              showToast("Success", "Copy URL success!", EToastStatus.SUCCESS);
            }}>
              Redemption Page <FaLink />
            </div>
          </Tooltip>
          <Button onClick={onClickAdd}>
            <FaPlus />
          </Button>
          <Button onClick={handleImport}>
            Import
          </Button>
          <Button isLoading={isLoading} onClick={handleExport}>
            Export
          </Button>
        </Flex>
      </Flex>
      <ImportModal
        open={showModalImport}
        onClose={handleClose}
        employers={employers}
        employerSelectedId={employerId}
      />
    </>
  );
};
