import {
  Input,
  FormControl,
  FormLabel,
  Textarea,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { ReactNode, useContext, ComponentProps } from "react";
import { TextTimezone } from "@components";
import { AdminContext } from "context";
import { employerField } from "enums";

interface IFormSub {
  children?: ReactNode;
  error?: string;
  name: string;
  showTimeZone?: boolean;
}

interface InputFieldProps extends ComponentProps<any> {
  icon?: ReactNode;
  iconRight?: ReactNode;
}

export const EmployerFormSub = {
  Form: ({ children, error, name, showTimeZone }: IFormSub) => {
    const {
      currentTimezone: { timezone },
    } = useContext(AdminContext);
    return (
      <FormControl>
        <FormLabel display="flex" alignItems="center" gap={2}>
          {employerField[name]?.label}
          {showTimeZone && <TextTimezone value={timezone} />}
        </FormLabel>
        {children}
        {error && <div className="text-md text-red-500">{error}</div>}
      </FormControl>
    );
  },
  Input: ({ error, showTimeZone, ...props }: InputFieldProps & IFormSub) => (
    <EmployerFormSub.Form
      error={error}
      name={props.name}
      showTimeZone={showTimeZone}
    >
      <InputField
        {...props}
        {...(props.readOnly && { variant: "filled" })}
        placeholder={employerField[props.name]?.label}
      />
    </EmployerFormSub.Form>
  ),
  TextArea: ({ error, ...props }: InputFieldProps & IFormSub) => (
    <EmployerFormSub.Form error={error} name={props.name}>
      <Textarea {...props} placeholder={employerField[props.name]?.label} />
    </EmployerFormSub.Form>
  ),
};

const InputField = (props: InputFieldProps) => {
  const { icon, iconRight, ...rest } = props;
  return (
    <InputGroup>
      {icon && <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>}
      <Input
        focusBorderColor="purple.500"
        className="bg-white dark:bg-navy-600 dark:text-white"
        {...rest}
      />
      {iconRight && <InputRightElement>{iconRight}</InputRightElement>}
    </InputGroup>
  );
};
