import { Box, Text } from "@chakra-ui/react";
import { formatArrayToDetail } from "@utils";
import { FC, ReactNode } from "react";
import { IReadFileImportEmployee } from "types";

interface ImportConfirmationTextProps {
  detail: ReactNode;
  color: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

interface IProps {
  data?: IReadFileImportEmployee;
}

export const EmployeeImportSummary: FC<IProps> = ({ data }) => {
  const {
    invalidEmployeeIndexes = [],
    existedEmployeeCount,
    newEmployeeCount,
    termedEmployeeCount,
  } = data || {};

  return data ? (
    <ul className="ml-6 mt-4 list-disc">
      <ImportConfirmationText
        detail={newEmployeeCount}
        suffix={`new employee${newEmployeeCount > 1 ? "s" : ""} will be created`}
        color="green.500"
      />
      {!!invalidEmployeeIndexes.length && (
        <ImportConfirmationText
          detail={formatArrayToDetail(invalidEmployeeIndexes)}
          prefix={`Employee${
            invalidEmployeeIndexes.length > 1 ? "s" : ""
          } in row${invalidEmployeeIndexes.length > 1 ? "s" : ""}`}
          suffix={
            <>
              (according 'No' column) are invalid and{" "}
              <strong>will be skipped</strong>
            </>
          }
          color="red.500"
        />
      )}
      <ImportConfirmationText
        detail={existedEmployeeCount}
        suffix={`employee${
          existedEmployeeCount > 1 ? "s" : ""
        } are already exist`}
        color="orange.500"
      />
      <ImportConfirmationText
        detail={termedEmployeeCount}
        suffix={`employee${termedEmployeeCount > 1 ? "s" : ""} will be removed`}
        color="orange.500"
      />
    </ul>
  ) : null;
};

const ImportConfirmationText: React.FC<ImportConfirmationTextProps> = ({
  detail,
  color,
  prefix,
  suffix,
}) => {
  return (
    <li className="text-gray-700">
      {prefix}{" "}
      <Text as="span" color={color}>
        {detail}{" "}
      </Text>
      {suffix}
    </li>
  );
};
