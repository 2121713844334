export enum EEmployers {
  Id = "_id",
  EmployerName = "employerName",
  EmployerNickname = "employerNickname",
  TPAName = "TPAName",
  PartnerName = "partnerName",
  PartnerId = "partnerId",
  Edit = "edit",
  EligibleEmployees = "eligibleEmployees",
  Delete = "Delete",
  RedemptionUrl = "redemptionUrl",
}

export const employerField: {
  [key: string]: { label: string; class: string };
} = {
  [EEmployers.EmployerName]: { label: "Employer Name", class: "md:w-[20%]" },
  [EEmployers.EmployerNickname]: { label: "Employer Nickname", class: "md:w-[20%]" },
  [EEmployers.TPAName]: { label: "TPA Name", class: "md:w-[15%]" },
  [EEmployers.PartnerName]: { label: "Partner Name", class: "md:w-[15%]" },
  [EEmployers.RedemptionUrl]: { label: "Redemption Url", class: "md:w-[10%]" },
  [EEmployers.Id]: {
    label: "Eligible Employees",
    class: "md:w-[15%]",
  },
  [EEmployers.Edit]: { label: "Edit", class: "md:w-[10%]" },
};
