import { ColumnDef, createColumnHelper } from "@tanstack/table-core";
import { EDateTimeFormat } from "enums";
import {
  actionLoggingField,
  EActionLogging,
  effectEntities,
} from "enums/action-logging";
import moment from "moment";
import { IActionLogging } from "types/action-logging";
import { cellClass, headerClass } from "views/share-class";

const columnHelper = createColumnHelper<IActionLogging>();

const getEntityLabel = (data: string) => {
  const entity = effectEntities.find((item) => item.value === data);
  return entity ? entity.label : null;
};

export const columnsActionLogging: ColumnDef<IActionLogging, any>[] = [
  columnHelper.accessor(EActionLogging.CreatedAt, {
    id: EActionLogging.CreatedAt,
    header: () => (
      <p className={headerClass}>
        {actionLoggingField[EActionLogging.CreatedAt].label}
      </p>
    ),
    cell: (info) => (
      <p className={cellClass}>
        {info.getValue() &&
          moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR)}
      </p>
    ),
  }),
  columnHelper.accessor(EActionLogging.UserId, {
    id: EActionLogging.UserId,
    header: () => (
      <p className={headerClass}>
        {actionLoggingField[EActionLogging.UserId].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EActionLogging.Action, {
    id: EActionLogging.Action,
    header: () => (
      <p className={headerClass}>
        {actionLoggingField[EActionLogging.Action].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EActionLogging.EntityType, {
    id: EActionLogging.EntityType,
    header: () => (
      <p className={headerClass}>
        {actionLoggingField[EActionLogging.EntityType].label}
      </p>
    ),
    cell: (info) => (
      <p className={`${cellClass}`}>{getEntityLabel(info.getValue())}</p>
    ),
  }),
  columnHelper.accessor(EActionLogging.PreviousValue, {
    id: EActionLogging.PreviousValue,
    header: () => (
      <p className={headerClass}>
        {actionLoggingField[EActionLogging.PreviousValue].label}
      </p>
    ),
    cell: (info) => {
      const value = info.getValue();
      const data = value
        ? Object.entries(value).map(([key, val]) => (
            <p key={key} className={cellClass}>
              <strong>{key}: </strong> {val !== null ? val.toString() : "null"}
            </p>
          ))
        : null;

      return (
        <div className={cellClass} style={{ flexDirection: "column" }}>
          {data ? data : <p>No data available</p>}
        </div>
      );
    },
  }),

  columnHelper.accessor(EActionLogging.NewValue, {
    id: EActionLogging.NewValue,
    header: () => (
      <p className={headerClass}>
        {actionLoggingField[EActionLogging.NewValue].label}
      </p>
    ),
    cell: (info) => {
      const value = info.getValue();
      const data = value
        ? Object.entries(value).map(([key, val]) => (
            <p key={key} className={cellClass}>
              <strong>{key}: </strong> {val !== null ? val.toString() : "null"}
            </p>
          ))
        : null;

      return (
        <div className={cellClass} style={{ flexDirection: "column" }}>
          {data ? data : <p>No data available</p>}
        </div>
      );
    },
  }),
];
