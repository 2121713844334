import { getToken } from "@utils";
import axios, { CreateAxiosDefaults, InternalAxiosRequestConfig } from "axios";
import { AdminContext } from "context";
import { useContext, useMemo } from "react";

const apiBaseConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_URL || "",
  headers: {
    Accept: "application/json",
  },
};

export const apiInstance = axios.create(apiBaseConfig);

export const apiNoAuthInstance = axios.create(apiBaseConfig);

apiInstance.interceptors.request.use(
  (config) => {
    const token = getToken("AccessToken");
    if (!token) return config;

    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    } as InternalAxiosRequestConfig<any>;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const AxiosInterceptor = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { handleUnauthorized } = useContext(AdminContext);

  useMemo(() => {
    const responseInterceptor = apiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response?.status === 401) {
          handleUnauthorized();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return <>{children}</>;
};
