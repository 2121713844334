import { Box, Text } from "@chakra-ui/react";
import { FC, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { getPartnerListForCode } from "services";
import { IPartnerForm } from "types";

interface IProps {
  handlePartnerChange: (e: { value: string; label: string }) => void;
  data: any;
  error?: string
}

export const SelectPartner: FC<IProps> = ({ handlePartnerChange, data, error }) => {
  const [partnerList, setPartnerList] = useState<IPartnerForm[]>();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [pagePartners, setPagePartners] = useState(1);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setLoadingPartners(true);
        const res = await getPartnerListForCode(pagePartners, searchValue);
        if (!res?.result) return;
        const newPartnerList = res.result?.map((item: any) => {
          return { value: item._id, label: item.name };
        });
        if (pagePartners === 1) {
          setPartnerList(newPartnerList);
        } else {
          setPartnerList((prev) => [...prev, ...newPartnerList]);
        }
        setHasNextPage(res.hasNextPage);
      } catch (err) {
      } finally {
        setLoadingPartners(false);
      }
    })();
  }, [pagePartners, searchValue]);

  const options = useMemo(
    () => [
      ...(partnerList ? partnerList : []),
      ...(loadingPartners
        ? [{ label: <Text align="center">Loading...</Text>, value: undefined }]
        : []),
    ],
    [partnerList, loadingPartners]
  );

  const handleInputChange = (inputValue: string) => {
    setSearchValue(inputValue);
  };

  const handleScrollToBottomPartners = () => {
    if (!hasNextPage) return;
    setPagePartners((prev) => prev + 1);
  };

  return (
    <Box>
      <Select
        defaultValue={{
          label: data?.partnerName || "Select",
          value: data?.partnerId,
        }}
        onChange={handlePartnerChange}
        options={options}
        isOptionDisabled={(option) => !option.value}
        onMenuScrollToBottom={handleScrollToBottomPartners}
        isLoading={loadingPartners}
        onInputChange={handleInputChange}
      />
      {error && <div className="text-md text-red-500">{error}</div>}
    </Box>
  );
};
