import { Button, Flex } from "@chakra-ui/react";
import { OTP_LENGTH } from "@constants";
import { isValidOtp, storeAccessTokens } from "@utils";
import { ClipboardEvent, useCallback, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { verifyOTP } from "services";

type IProps = {
  resendOTP: () => void;
  email: string;
};

export const VerifyOTP = ({ resendOTP, email }: IProps) => {
  const [otp, setOtp] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const firstInputRef = useRef<HTMLInputElement>(null);

  const handleSignIn = async () => {
    if (otp.length < OTP_LENGTH) return;
    try {
      setIsSubmitting(true);
      const tokens = await verifyOTP(otp, email);
      storeAccessTokens(tokens);
      navigate("/admin");
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const containerStyle = useCallback((isError: boolean, isFocus: boolean) => {
    const baseClasses =
      "flex-1 w-auto h-[50px] px-3 text-[24px] tracking-[14.4px] font-bold leading-[28px] flex justify-center items-center border rounded-[8px] mt-[36px] transition-colors justify-between";
    const textColor = isError && !isFocus ? "text-red-600" : "text-stone-700";
    const borderColor = (() => {
      if (isFocus) return "border-[black]";
      if (isError) return "border-red-600";
      return "border-[gray-600]";
    })();

    return [baseClasses, textColor, borderColor].join(" ");
  }, []);

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-4 md:mx-0 md:px-6 lg:mb-10 lg:items-center lg:justify-center">
      <div className="mt-[10vh] w-full max-w-full flex-1 flex-col items-center lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Enter the code we sent to login to your account:
        </h4>

        <Flex
          onClick={() => {
            if (isFocus) return;
            setIsFocus(true);
            firstInputRef.current?.focus();
          }}
        >
          <OTPInput
            shouldAutoFocus={true}
            value={otp}
            onChange={setOtp}
            numInputs={OTP_LENGTH}
            containerStyle={containerStyle(!!error, isFocus)}
            inputStyle="flex-1 w-10 h-12 p-0 text-2xl font-bold text-center border-none outline-none mx-1"
            inputType="tel"

            renderInput={(props, idx) => (
              <input
                {...props}
                {...(idx === 0 && { ref: firstInputRef })}
                onBlur={() => setIsFocus(false)}
                onFocus={(e) => {
                  e.stopPropagation();
                  setIsFocus(true);
                  setError(null);
                }}
              />
            )}

            onPaste={(e: ClipboardEvent<HTMLDivElement>) =>
              setOtp(
                e.clipboardData
                  .getData("text")
                  .replace(/\D/g, "")
                  .slice(0, OTP_LENGTH)
              )
            }
          />
        </Flex>

        {error && <h6 className="mt-1 text-red-600">{error}</h6>}

        <Button
          isLoading={isSubmitting}
          variant="brandPrimary"
          className="mt-5 mt-9 w-full bg-brand-500 text-base font-medium text-white hover:bg-brand-600 disabled:bg-gray-300 disabled:text-gray-900 dark:bg-white dark:text-navy-600 dark:hover:bg-navy-600 dark:hover:text-white dark:disabled:hover:bg-gray-300 dark:disabled:hover:text-gray-900"
          type="submit"
          isDisabled={!isValidOtp(otp)}
          onClick={handleSignIn}
        >
          Sign in
        </Button>

        <h6
          className="text-black mt-4 cursor-pointer font-bold underline"
          onClick={() => resendOTP()}
        >
          Didn't receive your code?
        </h6>
      </div>
    </div>
  );
};
