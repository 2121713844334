import { ColumnDef, createColumnHelper } from "@tanstack/table-core";
import { EEmployers, employerField } from "enums";
import { MdOutlineModeEdit } from "react-icons/md";
import { IEmployer } from "types";
import { cellClass, headerClass } from "views/share-class";
import { RxExternalLink } from "react-icons/rx";
import { CopyButton } from "@components";

const columnHelper = createColumnHelper<IEmployer>();

export const configColumnsEmployers = (): ColumnDef<IEmployer, any>[] => {
  return [
    columnHelper.accessor(EEmployers.EmployerName, {
      id: EEmployers.EmployerName,
      header: () => (
        <p className={headerClass}>
          {employerField[EEmployers.EmployerName].label}
        </p>
      ),
      cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
    }),
    columnHelper.accessor(EEmployers.EmployerNickname, {
      id: EEmployers.EmployerNickname,
      header: () => (
        <p className={headerClass}>
          {employerField[EEmployers.EmployerNickname].label}
        </p>
      ),
      cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
    }),
    columnHelper.accessor(EEmployers.TPAName, {
      id: EEmployers.TPAName,
      header: () => (
        <p className={headerClass}>{employerField[EEmployers.TPAName].label}</p>
      ),
      cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
    }),
    columnHelper.accessor(EEmployers.PartnerName, {
      id: EEmployers.PartnerName,
      header: () => (
        <p className={headerClass}>
          {employerField[EEmployers.PartnerName].label}
        </p>
      ),
      cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
    }),
    columnHelper.accessor(EEmployers.RedemptionUrl, {
      id: EEmployers.RedemptionUrl,
      header: () => (
        <p className={headerClass}>
          {employerField[EEmployers.RedemptionUrl].label}
        </p>
      ),
      cell: (info) => <CopyButton value={info.getValue()}/>,
    }),
    columnHelper.accessor(EEmployers.Id, {
      id: EEmployers.Id,
      header: () => (
        <p className={headerClass}>{employerField[EEmployers.Id].label}</p>
      ),
      cell: (info) => (
        <a
          className="hover:opacity-70"
          title="Redirect to employee page"
          href={`/admin/eligible-employees?employerId=${info.getValue()}`}
        >
          <RxExternalLink size={18} color="black" />
        </a>
      ),
    }),
    columnHelper.accessor(EEmployers.PartnerName, {
      id: EEmployers.Edit,
      header: () => (
        <p className={headerClass}>{employerField[EEmployers.Edit].label}</p>
      ),
      cell: () => (
        <button>
          <MdOutlineModeEdit
            size={18}
            color="black"
            className="dark:!text-white"
          />
        </button>
      ),
    }),
  ];
};
