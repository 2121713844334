import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";

export const InputField = (
  props: React.ComponentProps<typeof Input> & { icon?: React.ReactNode, iconRight?: React.ReactNode}
) => {
  const { icon, iconRight, ...rest } = props;
  return (
    <InputGroup>
      {icon && <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>}
      <Input
        focusBorderColor="purple.500"
        className="bg-white dark:bg-navy-600 dark:text-white"
        {...rest}
      />
      {iconRight && <InputRightElement>{iconRight}</InputRightElement>}
    </InputGroup>
  );
};