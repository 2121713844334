import { customStyles } from "@utils";
import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { IPagable } from "types";
import "../style.css";
import { PAGE_SIZE } from "@constants";

const btnPaginationClass =
  "font-semibold rounded w-8 h-8 flex items-center justify-center text-gray-600 hover:bg-brand-500 hover:text-white dark:disable:bg-navy-600 dark:text-gray-300 dark:hover:bg-brand-500 dark:hover:text-white";

const options = [
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

interface IOption {
  label: string;
  value: number;
}

export const Pagination = <T,>({
  pageUsers,
  pageParam,
  setPageSize,
}: {
  pageUsers?: IPagable<T[]>;
  setPageSize: (val: number) => void;
  pageParam: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const wrapperRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedSize, setSelectedSize] = useState<IOption>({
    label: PAGE_SIZE.toString(),
    value: PAGE_SIZE,
  });

  useEffect(() => {
    if (wrapperRef.current) {
      const selectedButton = wrapperRef.current.querySelector(
        ".list-pagination-number button.bg-brand-500"
      );
      const buttonContainer = wrapperRef.current.querySelector(
        ".list-pagination-number"
      );
      if (selectedButton) {
        const selectedButtonRect = selectedButton.getBoundingClientRect();
        const buttonContainerRect = buttonContainer.getBoundingClientRect();
        const scrollLeft = selectedButtonRect.left - buttonContainerRect.left;
        setScrollLeft(scrollLeft);
      }
    }
  }, [pageParam]);

  useEffect(() => {
    wrapperRef.current.scrollLeft = scrollLeft;
  }, [scrollLeft]);

  const handlePagination = (pageIndex: number) => {
    pageIndex === 0 && (pageIndex = 1);
    pageIndex > pageUsers?.pageCount && (pageIndex = pageUsers?.pageCount);
    if (pageIndex <= pageUsers?.pageCount) {
      const currentParams = Object.fromEntries(searchParams.entries());
      setSearchParams({ ...currentParams, page: pageIndex.toString() });
    }
  };

  const handleSizeChange = (newValue: IOption) => {
    setSelectedSize(newValue);
    setPageSize(newValue.value);
  };

  return (
    <div className="md:flex-no-wrap flex flex-wrap items-center justify-center gap-2 md:gap-20">
      <div className="flex items-center gap-1">
        <div>Records per page</div>
        <strong>
          <Select
            options={options}
            isMulti={false}
            onChange={handleSizeChange}
            value={selectedSize}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            classNamePrefix="react-select"
          />
        </strong>
      </div>
      <div className="flex items-center gap-2 md:gap-10">
        <div className="flex items-center gap-2">
          <button
            className={btnPaginationClass}
            onClick={() => handlePagination(1)}
            disabled={pageParam === "1"}
          >
            <FaAnglesLeft />
          </button>
          <button
            className={btnPaginationClass}
            onClick={() => handlePagination(Number(pageParam) - 1)}
            disabled={pageParam === "1"}
          >
            <FaChevronLeft />
          </button>
        </div>
        <div
          className="wrapper-pagination-number max-w-[118px] md:max-w-[200px]"
          ref={wrapperRef}
        >
          <div className="list-pagination-number">
            {pageUsers &&
              Array.from(
                { length: pageUsers.pageCount },
                (_, index) => index
              ).map((pageIndex) => (
                <button
                  key={pageIndex}
                  className={`${btnPaginationClass} ${
                    pageParam === (pageIndex + 1).toString()
                      ? "bg-brand-500 text-white"
                      : "dark:text-gray-300 dark:hover:bg-brand-500 dark:hover:text-white"
                  }`}
                  onClick={() => handlePagination(pageIndex + 1)}
                  disabled={pageParam === (pageIndex + 1).toString()}
                >
                  {pageIndex + 1}
                </button>
              ))}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            className={btnPaginationClass}
            onClick={() => handlePagination(Number(pageParam) + 1)}
            disabled={pageParam === pageUsers?.pageCount?.toString()}
          >
            <FaChevronRight />
          </button>
          <button
            className={btnPaginationClass}
            onClick={() => handlePagination(pageUsers?.pageCount || 1)}
            disabled={pageParam === pageUsers?.pageCount?.toString()}
          >
            <FaAnglesRight />
          </button>
        </div>
      </div>
      {!!pageUsers?.pageCount && (
        <div className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            <input
              type="number"
              className="h-7 w-7 rounded text-center dark:bg-navy-700"
              value={pageParam}
              onChange={(e) => handlePagination(Number(e))}
              min={1}
              max={pageUsers.pageCount}
            />
            of {pageUsers.pageCount}
          </strong>
        </div>
      )}
    </div>
  );
};
