import { Input, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { subsField } from "enums";
import { InputField } from "./InputField";
import { ReactNode, useContext } from "react";
import { TextTimezone } from "@components";
import { AdminContext } from "context";

interface IFormSub {
  children?: ReactNode;
  error?: string;
  name: string;
  showTimeZone?: boolean;
}

export const FormSub = {
  Form: ({children, error, name, showTimeZone}: IFormSub) => {
    const { currentTimezone: { timezone } } = useContext(AdminContext);
    return (
      <FormControl>
        <FormLabel display="flex" alignItems="center" gap={2}>
          {subsField[name]?.label}
          {showTimeZone && <TextTimezone value={timezone}/>}
        </FormLabel>
        {children}
        {error && <div className="text-md text-red-500">{error}</div>}
      </FormControl>
    )
  },
  Input: ({error, showTimeZone, ...props}: React.ComponentProps<typeof Input> & IFormSub) => (
    <FormSub.Form error={error} name={props.name} showTimeZone={showTimeZone}>
      <InputField
        {...props}
        {...(props.readOnly && { variant: "filled" })}
        placeholder={subsField[props.name]?.label}
      />
    </FormSub.Form>
  ),
  TextArea: ({error, ...props}: React.ComponentProps<typeof Textarea> & IFormSub) => (
    <FormSub.Form error={error} name={props.name}>
      <Textarea {...props} placeholder={subsField[props.name]?.label} />
    </FormSub.Form>
  ),
};
