export enum ESystemUser {
  Id = "_id",
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  PhoneNumber = "phoneNumber",
  CreatedTime = "createdTime",
  Status = "status",
  SubRole = "subRole",
}

export enum ESubRole {
  ADMIN = "admin",
  EMPLOYER = "employer",
}

export const systemUserFields: {
  [key: string]: { label: string; class: string };
} = {
  [ESystemUser.FirstName]: { label: "First Name", class: "md:w-[20%]" },
  [ESystemUser.LastName]: {
    label: "Last Name",
    class: "md:w-[20%]",
  },
  [ESystemUser.PhoneNumber]: { label: "Phone Number", class: "md:w-[15%]" },
  [ESystemUser.Email]: { label: "Email", class: "md:w-[15%]" },
  [ESystemUser.CreatedTime]: { label: "Signup Date", class: "md:w-[10%]" },
  [ESystemUser.Status]: {
    label: "Status",
    class: "md:w-[15%]",
  },
  [ESystemUser.SubRole]: { label: "Role", class: "md:w-[10%]" },
};
